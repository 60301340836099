import Papa, { parse } from "papaparse";
import { L, O } from "ts-toolbelt";

/**
 * The Papaparse `parse` method utilizes a callback to asynchronously
 * return results. We typically use async/await to handle asynchronous
 * operations, so this helper just wraps `parse` in a Promise to better
 * fit into our other async operations.
 *
 * Note: This currently only returns the first file chunk, as we currently only
 * use this to parse the header row of a CSV file. If we need to parse the entire
 * file, we'll need to update this method to handle that.
 */
const parseAsPromise = async <T = string[] | undefined>(
  ...[file, opts]: L.Update<
    Parameters<typeof Papa.parse<T>>,
    "1",
    O.Omit<Parameters<typeof Papa.parse<T>>[1], "step">
  >
): Promise<Papa.ParseStepResult<T>> => {
  return new Promise((resolve, reject) => {
    parse(file, {
      ...opts,
      step: (results: Papa.ParseStepResult<T>) => {
        resolve(results);
      },
      error: (e) => {
        reject(e);
      },
    });
  });
};

export default parseAsPromise;
