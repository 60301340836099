import { GetTaxReportStatus, TaxReportStatus } from "@taxbit-dashboard/rest";

const isDownloadReportButtonDisabled = ({
  reportStatus,
}: {
  reportStatus?: GetTaxReportStatus;
}) => {
  if (reportStatus) {
    return (
      !reportStatus.lastGenerated ||
      reportStatus.status === TaxReportStatus.InProgress
    );
  } else {
    return true;
  }
};

export default isDownloadReportButtonDisabled;
