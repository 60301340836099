import { Anchor, Body, H4 } from "@taxbit-private/cosmic";

import {
  HelpArticleLinkTrackingId,
  curingDocumentationLink,
  treatyCountryMismatchCuringDocumentationLink,
} from "./helpArticleLinks";

export const resubmissionText = (
  <>
    <H4>Action Required</H4>
    <Body>
      This issue requires the account owner to submit new tax documentation.
      After submission, the tax document will be reviewed to determine whether
      there are any outstanding issues that need to be resolved.
    </Body>
  </>
);

export const usIndiciaText = (
  <>
    <H4>Action Required</H4>
    <Body>
      This issue requires curing documentation to be obtained. Alternatively,
      the account owner can submit a Form W-9.
    </Body>
    <Body>
      If you have obtained acceptable curing documentation, click below to
      resolve the issue. See{" "}
      <Anchor
        label="here"
        href={curingDocumentationLink}
        trackingId={HelpArticleLinkTrackingId.CuringDocumentation}
      />{" "}
      for a checklist on what to look for before accepting curing documentation.
    </Body>
  </>
);

export const resubmissionOrCuringText = (
  <>
    <H4>Action Required</H4>
    <Body>
      This issue requires curing documentation to be obtained. Alternatively,
      the account owner can submit new tax documentation to resolve the issue.
    </Body>
    <Body>
      If you have obtained acceptable curing documentation, click below to
      resolve the issue. See{" "}
      <Anchor
        label="here"
        href={curingDocumentationLink}
        trackingId={HelpArticleLinkTrackingId.CuringDocumentation}
      />{" "}
      for a checklist on what to look for before accepting curing documentation.
    </Body>
    <Body>
      If the account owner chooses to submit new tax documentation, the new tax
      document will be reviewed to determine whether the issue has been
      resolved.
    </Body>
  </>
);

export const treatyCountryMismatchActionRequiredText = (
  <>
    <H4>Action Required</H4>
    <Body>
      This warning requires additional information and/or documentation
      establishing residency in the treaty country to be obtained, in order to
      rely upon the treaty claim information provided. Alternatively, the
      account owner can submit a revised Form W-8/9 that resolves this mismatch.
    </Body>
    <Body>
      If you have obtained the appropriate information and/or documentation,
      click below to resolve the warning. See{" "}
      <Anchor
        label="here"
        href={treatyCountryMismatchCuringDocumentationLink}
        trackingId={
          HelpArticleLinkTrackingId.TreatyCountryMismatchCuringDocumentation
        }
      />{" "}
      for additional details on this warning.
    </Body>
  </>
);
