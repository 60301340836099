import { ToastConfig, useDashboardStore } from "@taxbit-dashboard/commons";
import { useState } from "react";

import { useInitiateAccountsCsvExport } from "../../../../api/accounts-exports/accountsExportApi";
import { TOAST_TIMEOUT } from "../../../../utils/toastTimeout";
import useAccountsTableData from "../useAccountsTableData";

export enum DownloadAccountsTrackingId {
  ErrorToast = "accountsCsvErrorToast",
  SuccessToast = "accountsCsvSuccessToast",
  InfoToast = "accountsCsvInfoToast",
}

const errorToastContent: ToastConfig = {
  message: "Accounts failed to export. Please try again.",
  trackingId: DownloadAccountsTrackingId.ErrorToast,
  variant: "danger",
  timeoutMs: TOAST_TIMEOUT,
};

const useGetAccountsCsv = () => {
  const [isLoading, setIsLoading] = useState(false);

  const addToast = useDashboardStore((store) => store.addToast);

  const { urlParams } = useAccountsTableData();

  const { mutate: generateAccountsCsvUrl } = useInitiateAccountsCsvExport();

  const generateAccountsCsv = (shouldExportAllAccounts = false) => {
    setIsLoading(true);

    addToast({
      message:
        "Your accounts are being exported. The CSV file will appear in the notifications tab when ready to download.",
      trackingId: DownloadAccountsTrackingId.InfoToast,
      timeoutMs: TOAST_TIMEOUT,
    });

    generateAccountsCsvUrl(shouldExportAllAccounts ? undefined : urlParams, {
      onSuccess: () => {
        setIsLoading(false);
      },
      onError: () => {
        setIsLoading(false);
        addToast(errorToastContent);
      },
    });
  };

  return {
    isLoading,
    generateAccountsCsv,
  };
};

export default useGetAccountsCsv;
