import { Alert } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import pluralize from "pluralize";
import { useEffect, useMemo, useState } from "react";

import { useEligibilityDataContext } from "../context/useEligibilityData";

export const FORM_DATA_UNDER_THRESHOLD_ALERT_TRACKING_ID =
  "irEligibilityFormDataUnderThresholdAlert";

const FormDataUnderThresholdAlert = () => {
  const { eligibilityAlerts } = useEligibilityDataContext();
  const [isWarningPresent, setIsWarningPresent] = useState(
    !!eligibilityAlerts?.hasFormDataUnderThreshold
  );

  useEffect(() => {
    setIsWarningPresent(!!eligibilityAlerts?.hasFormDataUnderThreshold);
  }, [eligibilityAlerts]);

  const { formatWholeQuantity } = useCosmicLocalizationContext();

  const warningMessage = useMemo(() => {
    const count = eligibilityAlerts?.hasFormDataUnderThreshold ?? 0;

    return `There ${pluralize("is", count)} ${formatWholeQuantity(count)} ${pluralize(
      "account",
      count
    )} with Form data that ${count <= 1 ? "does" : "do"} not meet the federal threshold.`;
  }, [eligibilityAlerts?.hasFormDataUnderThreshold, formatWholeQuantity]);

  if (isWarningPresent) {
    return (
      <Alert
        onClose={() => setIsWarningPresent(false)}
        variant="warning"
        trackingId={FORM_DATA_UNDER_THRESHOLD_ALERT_TRACKING_ID}
      >
        {warningMessage}
      </Alert>
    );
  } else {
    return undefined;
  }
};

export default FormDataUnderThresholdAlert;
