import { AccountV2 } from "@taxbit-dashboard/rest";

import { useAccountOwnerTaxDocumentContext } from "../../../../hooks/useGetAccountOwnerTaxDocumentData";
import { getMostRecentFairMarketValue } from "../../../../utils/getMostRecentFairMarketValue";

export type AccountRowData = Omit<AccountV2, "accountOwner"> & {
  mostRecentFairMarketValue?: string;
};

export const useGetAccountDetails = () => {
  const { shouldUnmaskValue, account, accountOwner, isAccountLoading, payers } =
    useAccountOwnerTaxDocumentContext();

  const accountData: AccountRowData | undefined = account
    ? {
        ...account,
        mostRecentFairMarketValue: getMostRecentFairMarketValue(
          account?.yearEndFairMarketValue
        ),
      }
    : undefined;

  return {
    shouldUnmaskValue,
    account: accountData,
    accountOwner,
    isAccountLoading,
    payers,
  };
};
