import { DashboardQueryKey } from "@taxbit-dashboard/commons";
import { NotificationCategory } from "@taxbit-dashboard/rest";

const queryKeysByPage = {
  ingest: [DashboardQueryKey.Files],
  accountDetailsTaxForms: [DashboardQueryKey.TaxForms],
  irwEligibility: [
    DashboardQueryKey.EligibilityData,
    DashboardQueryKey.EligibilityAlerts,
    DashboardQueryKey.EligibilityCounts,
  ],
  irwForms: [DashboardQueryKey.IrFormsAggregates, DashboardQueryKey.IrForms],
  reports: [DashboardQueryKey.TaxReportStatus],
};

/**
 * Notifications indicate that backend data has changed, which is a signal to the frontend to
 * refresh the appropriate data. This map associates each notification type with the data queries
 * that may have been altered by whatever backend change or process triggered the notification, and
 * is used to invalidate the set of queries upon each notification coming in.
 *
 * Note: This set is exhaustive so that all new notifications are vetted for necessary query clearing,
 * even though some notifications do not need any queries to be invalidated when they come in.
 */
const notificationQueryMap: Record<NotificationCategory, DashboardQueryKey[]> =
  {
    [NotificationCategory.AccountDeletionComplete]: [...queryKeysByPage.ingest],
    [NotificationCategory.AccountDeletionValidationComplete]: [
      ...queryKeysByPage.ingest,
    ],
    [NotificationCategory.AccountIngestionComplete]: [
      ...queryKeysByPage.ingest,
    ],
    [NotificationCategory.AccountIngestionValidationComplete]: [
      ...queryKeysByPage.ingest,
    ],
    [NotificationCategory.AccountsExportComplete]: [],
    [NotificationCategory.DeletionFailed]: [...queryKeysByPage.ingest],
    [NotificationCategory.DeletionValidationFailed]: [
      ...queryKeysByPage.ingest,
    ],
    [NotificationCategory.EligibilityDataSyncComplete]: [],
    [NotificationCategory.EligibilityDataSyncFailed]: [],
    [NotificationCategory.EligibilityExportComplete]: [],
    [NotificationCategory.EligibilityProcessingComplete]: [],
    [NotificationCategory.EligibilityProcessingFailed]: [],
    [NotificationCategory.ExportFailed]: [],
    [NotificationCategory.FormDeletionComplete]: [...queryKeysByPage.ingest],
    [NotificationCategory.FormDeletionValidationComplete]: [
      ...queryKeysByPage.ingest,
    ],
    [NotificationCategory.FormDownloadComplete]: [],
    [NotificationCategory.FormDownloadFailed]: [],
    [NotificationCategory.FormDownloadStarted]: [],
    [NotificationCategory.FormFilingComplete]: [
      ...queryKeysByPage.accountDetailsTaxForms,
      ...queryKeysByPage.irwEligibility,
      ...queryKeysByPage.irwForms,
    ],
    [NotificationCategory.FormFilingFailed]: [
      ...queryKeysByPage.accountDetailsTaxForms,
      ...queryKeysByPage.irwEligibility,
      ...queryKeysByPage.irwForms,
    ],
    [NotificationCategory.FormFilingStarted]: [],
    [NotificationCategory.FormGenerationComplete]: [
      ...queryKeysByPage.accountDetailsTaxForms,
      ...queryKeysByPage.irwEligibility,
      ...queryKeysByPage.irwForms,
    ],
    [NotificationCategory.FormGenerationFailed]: [
      ...queryKeysByPage.accountDetailsTaxForms,
      ...queryKeysByPage.irwEligibility,
      ...queryKeysByPage.irwForms,
    ],
    [NotificationCategory.FormGenerationStarted]: [],
    [NotificationCategory.FormIngestionComplete]: [...queryKeysByPage.ingest],
    [NotificationCategory.FormIngestionValidationComplete]: [
      ...queryKeysByPage.ingest,
    ],
    [NotificationCategory.FormsExportComplete]: [],
    [NotificationCategory.IngestionFailed]: [...queryKeysByPage.ingest],
    [NotificationCategory.IngestionValidationFailed]: [
      ...queryKeysByPage.ingest,
    ],
    [NotificationCategory.InvalidFile]: [...queryKeysByPage.ingest],
    [NotificationCategory.QaPackageGenerationComplete]: [],
    [NotificationCategory.QaPackageGenerationFailed]: [],
    [NotificationCategory.QaPackageGenerationStarted]: [],
    [NotificationCategory.ReportFailed]: [...queryKeysByPage.reports],
    [NotificationCategory.ReportGenerationComplete]: [
      ...queryKeysByPage.reports,
    ],
    [NotificationCategory.TransactionDeletionComplete]: [
      ...queryKeysByPage.ingest,
    ],
    [NotificationCategory.TransactionDeletionValidationComplete]: [
      ...queryKeysByPage.ingest,
    ],
    [NotificationCategory.TransactionIngestionComplete]: [
      ...queryKeysByPage.ingest,
    ],
    [NotificationCategory.TransactionIngestionValidationComplete]: [
      ...queryKeysByPage.ingest,
    ],
  };

export default notificationQueryMap;
