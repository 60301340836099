import { useIsMutating } from "@tanstack/react-query";
import {
  DashboardMutationKey,
  UserPermission,
  createSingleInstanceHookContext,
  useDashboardFeatureFlags,
  useDashboardStore,
  useLocalPageBanner,
  useUserPermission,
} from "@taxbit-dashboard/commons";
import {
  DashboardFormType,
  IrForm,
  UnexpectedResponseError,
} from "@taxbit-dashboard/rest";
import {
  useCosmicTable,
  getEnUsErrorEmptyStateProps,
  useSearchEmptyStateProps,
} from "@taxbit-private/cosmic";
import { useCallback, useMemo, useState } from "react";

import {
  useCreateTaxFormsFiling,
  useGetFormsAggregates,
  useRescindIrForms,
} from "../../../../api/information-reporting/forms/formsApi";
import useFormFeatureFlagDropdowns from "../../shared/useFormFeatureFlagDropdowns";
import {
  getDeleteErrorToastContent,
  getErrorFilingToastContent,
  successDeleteToastContent,
  successFilingToastContent,
} from "../table/actions/toastContents";
import useFormsTableColumns from "../table/useFormsTableColumns";
import useFormsTableData from "../table/useFormsTableData";
import useFormsTableSort from "../table/useFormsTableSort";

enum IrFormsTrackingId {
  CosmicTable = "ir-forms-table",
}

const supportedFormsForFiling = new Set([
  DashboardFormType.Irs1099B,
  DashboardFormType.Irs1099Misc,
  DashboardFormType.Irs1099K,
  DashboardFormType.Irs1099R,
  DashboardFormType.Irs5498,
  DashboardFormType.Irs1099Nec,
  DashboardFormType.Irs1099Int,
  DashboardFormType.Irs1099Div,
  DashboardFormType.Irs1042S,
]);

const useIrForms = () => {
  const [selectedRows, setSelectedRows] = useState<IrForm[]>([]);
  // We allow up to 10k account IDs in this filter
  // Therefore we can't put it directly in the URL and we need to keep in in a state
  const [accountExternalIdsFilter, setAccountExternalIdsFilter] =
    useState<string>("");

  const clearAccountExternalIdsFilter = useCallback(() => {
    setAccountExternalIdsFilter("");
  }, [setAccountExternalIdsFilter]);

  const formsDropdownData = useFormFeatureFlagDropdowns({
    featureFlag: "formsPageYearsByFormType",
    onFormChangeCallback: clearAccountExternalIdsFilter,
    onYearChangeCallback: clearAccountExternalIdsFilter,
  });
  const addToast = useDashboardStore((state) => state.addToast);
  const { hasTaxFormsFilingAccess } = useDashboardFeatureFlags();

  const canCreateTaxForms = useUserPermission(UserPermission.CreateTaxForms);
  const shouldShowFilingActions =
    hasTaxFormsFilingAccess &&
    canCreateTaxForms &&
    formsDropdownData.currentForm &&
    supportedFormsForFiling.has(formsDropdownData.currentForm);

  const { mutate: createTaxFormsFiling } = useCreateTaxFormsFiling({
    formType: formsDropdownData.currentForm,
    formDate: formsDropdownData.currentYear,
  });

  const isCreatingFiling =
    useIsMutating({
      mutationKey: [
        DashboardMutationKey.IrFormsFiling,
        {
          formType: formsDropdownData.currentForm,
          formDate: formsDropdownData.currentYear,
        },
      ],
    }) > 0;

  const handleSubmitFiling = useCallback(() => {
    createTaxFormsFiling(undefined, {
      onSuccess: () => {
        addToast(successFilingToastContent);
      },
      onError: (error) => {
        if (
          error instanceof UnexpectedResponseError &&
          error.detailedErrors?.[0]
        ) {
          addToast(getErrorFilingToastContent(error.detailedErrors[0].detail));
        } else {
          addToast(getErrorFilingToastContent());
        }
      },
    });
  }, [addToast, createTaxFormsFiling]);

  const {
    data: aggregates,
    isError: isErrorAggregates,
    isLoading: isLoadingAggregates,
  } = useGetFormsAggregates({
    formType: formsDropdownData.currentForm,
    formDate: formsDropdownData.currentYear,
  });

  const totalFormsGenerated =
    (aggregates?.generated.original ?? 0) +
    (aggregates?.generated.correction ?? 0) +
    (aggregates?.generated.voided ?? 0);

  const { mutate: rescindForms } = useRescindIrForms({
    formType: formsDropdownData.currentForm,
    formDate: formsDropdownData.currentYear,
    onSettled: (data, error) => {
      const errorCount = data?.errorCount ?? 0;
      const totalCount = data?.totalCount ?? 0;

      if (error || errorCount > 0) {
        addToast(
          getDeleteErrorToastContent({
            errorCount,
            totalCount,
          })
        );
      } else {
        addToast(successDeleteToastContent);
      }
    },
  });

  const isRescindingForms =
    useIsMutating({
      mutationKey: [
        DashboardMutationKey.IrFormsRescind,
        {
          formType: formsDropdownData.currentForm,
          formDate: formsDropdownData.currentYear,
        },
      ],
    }) > 0;

  const {
    isLoading: isFormsLoading,
    isError: isFormsError,
    data: irForms,
    ...formsTableData
  } = useFormsTableData({
    accountExternalIdsFilter,
    setAccountExternalIdsFilter,
  });

  const shouldDisableControls =
    formsTableData.shouldDisableControls || isRescindingForms;

  const irFormsTableSortProps = useFormsTableSort({
    sort: formsTableData.urlParams.sort,
    setFilterParams: formsTableData.setFilterParams,
  });
  const irFormsRows = useMemo(() => irForms ?? [], [irForms]);
  const columns = useFormsTableColumns();
  const memoizedGetRowKey = useCallback(({ id }: IrForm) => id, []);

  const searchEmptyStateProps = useSearchEmptyStateProps();

  const irFormsTableProps = useCosmicTable({
    trackingId: IrFormsTrackingId.CosmicTable,
    isManualSortable: true,
    getRowKey: memoizedGetRowKey,
    shouldShowAutomaticPlaceholders: true,
    rows: irFormsRows,
    columns,
    onRowSelect: setSelectedRows,
    isLoading: shouldDisableControls,
    emptyStateProps: isFormsError
      ? getEnUsErrorEmptyStateProps({ entity: "forms" })
      : searchEmptyStateProps,
    ...irFormsTableSortProps,
  });

  useLocalPageBanner({
    shouldShow: isRescindingForms,
    bannerConfig: {
      shouldShowSpinner: true,
      message: "We are deleting forms. Please wait a few moments.",
    },
  });

  const shouldDisableFiling =
    isLoadingAggregates ||
    shouldDisableControls ||
    !formsDropdownData.currentForm ||
    !formsDropdownData.currentYear ||
    totalFormsGenerated <= 0 ||
    isCreatingFiling;

  return {
    ...formsDropdownData,
    ...formsTableData,
    accountExternalIdsFilter,
    aggregates,
    handleSubmitFiling,
    irFormsTableProps,
    isErrorAggregates,
    isFormsError,
    isLoading: isLoadingAggregates || isFormsLoading,
    isLoadingAggregates,
    rescindForms,
    selectedForms: selectedRows,
    setAccountExternalIdsFilter,
    shouldDisableControls,
    shouldDisableFiling,
    shouldShowFilingActions,
    toggleAllSelectedForms:
      irFormsTableProps.tableInstance.toggleAllRowsSelected,
    totalFormsGenerated,
  };
};

export const {
  useContextHook: useIrFormsContext,
  Context: IrFormsContext,
  Provider: IrFormsContextProvider,
} = createSingleInstanceHookContext(useIrForms, "useIrForms");
