import {
  DashboardPrimaryTemplate,
  MissingDashboardConfigEmptyState,
  isDefined,
  useSavedScrollPosition,
} from "@taxbit-dashboard/commons";
import {
  ActionMenu,
  Box,
  Button,
  ContentEmptyState,
  ContentSpinner,
  Flex,
} from "@taxbit-private/cosmic";
import { useState } from "react";

import { useIrFormsContext } from "./context/useIrForms";
import ApproveFilingModal from "./filing-modal/ApproveFilingModal";
import FilingApprovalAlert from "./filing-modal/FilingApprovalAlert";
import DownloadFormsConfirmationModal from "./table/actions/DownloadFormsConfirmationModal";
import FormsTableContent from "./table/FormsTableContent";
import Totals from "./totals/Totals";
import IrFormYearUtilityDropdowns from "../shared/IrFormYearUtilityDropdowns";

export enum FormsTrackingId {
  DownloadFormsButton = "ir-forms-download-forms-button",
  EligibilityPageLink = "ir-forms-eligibility-page-link",
  LoadingSpinner = "ir-forms-loading-spinner",
  AdditionalActions = "ir-forms-additional-actions",
  FilingButton = "ir-forms-filing-button",
}

const Forms = () => {
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [isFilingModalOpen, setIsFilingModalOpen] = useState(false);
  const {
    aggregates,
    shouldDisableFiling,
    isLoading,
    currentForm,
    currentYear,
    shouldDisableControls,
    shouldShowFilingActions,
    totalFormsGenerated,
    ...irDropdownProps
  } = useIrFormsContext();

  useSavedScrollPosition({ key: "forms-page" });

  const content = (() => {
    if (!isDefined(currentForm) || !isDefined(currentYear)) {
      return <MissingDashboardConfigEmptyState />;
    }

    if (isLoading) {
      return <ContentSpinner trackingId={FormsTrackingId.LoadingSpinner} />;
    }

    if (aggregates && aggregates.total <= 0) {
      return (
        <Box>
          <ContentEmptyState
            title="No Forms Available"
            subtitleElement="Once forms are generated, they will appear here."
            avatarProps={{
              iconName: "file-text",
              variant: "secondary",
            }}
          />
        </Box>
      );
    }

    return (
      <>
        <Totals />
        <FormsTableContent />
      </>
    );
  })();

  return (
    <DashboardPrimaryTemplate
      title="Forms"
      utilityElement={
        isDefined(currentForm) && isDefined(currentYear) ? (
          <Flex direction="row" gap="s">
            <IrFormYearUtilityDropdowns
              {...irDropdownProps}
              isDisabled={isLoading}
              currentForm={currentForm}
              currentYear={currentYear}
            />
            <Button
              label="Download Forms"
              isDisabled={
                shouldDisableControls ||
                !aggregates?.total ||
                aggregates.total <= 0
              }
              onClick={() => setIsDownloadModalOpen(true)}
              trackingId={FormsTrackingId.DownloadFormsButton}
            />
            {shouldShowFilingActions && (
              <ActionMenu
                items={[
                  {
                    label: "Approve filing",
                    onClick: () => {
                      setIsFilingModalOpen(true);
                    },
                    iconName: "check-circle",
                    trackingId: FormsTrackingId.FilingButton,
                    isDisabled: shouldDisableFiling,
                    tooltipProps:
                      totalFormsGenerated <= 0
                        ? {
                            content:
                              "There are no forms available to be filed.",
                            appendTo: "body",
                            isInteractive: false,
                          }
                        : undefined,
                  },
                ]}
                size="large"
                trackingId={FormsTrackingId.AdditionalActions}
              />
            )}
          </Flex>
        ) : undefined
      }
    >
      <FilingApprovalAlert />
      {content}
      {isDownloadModalOpen && (
        <DownloadFormsConfirmationModal
          isOpen={isDownloadModalOpen}
          onClose={() => setIsDownloadModalOpen(false)}
          isBulkDownload={true}
        />
      )}
      {isFilingModalOpen && (
        <ApproveFilingModal
          isOpen={isFilingModalOpen}
          onClose={() => setIsFilingModalOpen(false)}
        />
      )}
    </DashboardPrimaryTemplate>
  );
};

export default Forms;
