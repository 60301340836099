import { fourDigitYearSchema } from "@taxbit-private/datetime";
import { useMemo } from "react";

import { useGetFormItemsYears } from "../../../api/overview/overviewApi";

const useFormItemYears = () => {
  const { data, isLoading } = useGetFormItemsYears();

  const formItemYears = useMemo(() => {
    if (data) {
      return Object.keys(data)
        .map((year) => fourDigitYearSchema.parse(year))
        .sort((a, b) => b - a);
    }
    return [];
  }, [data]);

  const hasFormItemYears = useMemo(
    () => formItemYears.length > 0,
    [formItemYears]
  );

  return {
    formItemYears,
    hasFormItemYears,
    isLoading,
  };
};

export default useFormItemYears;
