import { EligibilityDataItem, EligibilityStatus } from "@taxbit-dashboard/rest";
import { Body, Modal } from "@taxbit-private/cosmic";
import pluralize from "pluralize";
import { useMemo } from "react";

import useEligibilityOverrideConfirmationModal, {
  getEligibilityOverrideConfirmationModalTrackingIds,
} from "./useEligibilityOverrideConfirmationModal";
import { OverrideEligibilityAction } from "../../../../api/information-reporting/eligible-users/eligibleUsersApiTypes";

type RemoveFromScopeConfirmationModalProps = {
  onClose: () => void;
  isOpen: boolean;
  eligibilityItems: EligibilityDataItem[];
};

const RemoveFromScopeConfirmationModal = ({
  onClose,
  isOpen,
  eligibilityItems,
}: RemoveFromScopeConfirmationModalProps) => {
  const action = OverrideEligibilityAction.Remove;
  const accountIds = eligibilityItems.map((item) => item.accountId);
  const { isLoading, handleSubmit } = useEligibilityOverrideConfirmationModal({
    onClose,
    action,
    items: eligibilityItems,
  });

  const { ineligibleAccounts, generatedFormAccounts } = useMemo(() => {
    return eligibilityItems.reduce(
      (acc, item) => {
        if (item.hasFormGenerated) {
          return {
            ...acc,
            generatedFormAccounts: acc.generatedFormAccounts + 1,
          };
        } else if (
          item.status === EligibilityStatus.Removed ||
          item.status === EligibilityStatus.NonValidTaxCountry ||
          item.status === EligibilityStatus.NotReportable ||
          item.status === EligibilityStatus.Exempt
        ) {
          return { ...acc, ineligibleAccounts: acc.ineligibleAccounts + 1 };
        }

        return acc;
      },
      { ineligibleAccounts: 0, generatedFormAccounts: 0 }
    );
  }, [eligibilityItems]);

  const isPrimaryButtonDisabled = accountIds.length - ineligibleAccounts === 0;

  return (
    <Modal
      title="Remove Accounts From Scope"
      isOpen={isOpen}
      onClose={onClose}
      closeButtonTrackingId={
        getEligibilityOverrideConfirmationModalTrackingIds(action).CloseButton
      }
      secondaryButtonProps={{
        label: "Cancel",
        trackingId:
          getEligibilityOverrideConfirmationModalTrackingIds(action)
            .CancelButton,
        onClick: onClose,
      }}
      primaryButtonProps={{
        label: "Continue",
        trackingId:
          getEligibilityOverrideConfirmationModalTrackingIds(action)
            .SubmitButton,
        isLoading,
        isDisabled: isPrimaryButtonDisabled,
        loadingText: "Removing",
        variant: "button-primary",
        onClick: handleSubmit,
        tooltipProps: {
          content: "None of the selected accounts can be removed from scope.",
          isDisabled: !isPrimaryButtonDisabled,
        },
      }}
    >
      <Body>{`You have selected ${accountIds.length} ${pluralize(
        "account",
        accountIds.length
      )} to be removed from scope. Please confirm you want to continue.`}</Body>
      {(generatedFormAccounts > 0 || ineligibleAccounts > 0) && (
        <Body>
          <br />
          Please note:
          <ul>
            {ineligibleAccounts > 0 && (
              <li>
                {ineligibleAccounts} selected{" "}
                {pluralize("account", ineligibleAccounts)}{" "}
                {pluralize("is", ineligibleAccounts)} ineligible and will not be
                removed from scope.
              </li>
            )}
            {generatedFormAccounts > 0 && (
              <li>
                {generatedFormAccounts} selected{" "}
                {pluralize("account", generatedFormAccounts)}{" "}
                {pluralize("has", generatedFormAccounts)} a generated form and
                will be removed from scope. The generated{" "}
                {pluralize("form", generatedFormAccounts)} should be deleted.
              </li>
            )}
          </ul>
        </Body>
      )}
    </Modal>
  );
};

export default RemoveFromScopeConfirmationModal;
