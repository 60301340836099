import {
  useDashboardStore,
  useOrganizationId,
} from "@taxbit-dashboard/commons";
import { CompanyUser } from "@taxbit-dashboard/rest";
import { Modal, ModalProps, Body, Flex, Avatar } from "@taxbit-private/cosmic";

import { useRemoveUserFromOrganization } from "../../../../api/organizationMembersApi";

type Props = Pick<ModalProps, "isOpen" | "onClose"> & {
  user: CompanyUser;
};

const RemoveCompanyUserModal: React.FC<Props> = ({ onClose, isOpen, user }) => {
  const organizationId = useOrganizationId();

  const { mutate: removeUser, isLoading: isLoadingRemoveUser } =
    useRemoveUserFromOrganization(organizationId);
  const addToast = useDashboardStore((store) => store.addToast);

  return (
    <Modal
      title="Confirm Delete"
      isOpen={isOpen}
      onClose={onClose}
      closeButtonTrackingId="delete-user-close-button"
      secondaryButtonProps={{
        label: "Cancel",
        trackingId: "delete-user-cancel-button",
        onClick: onClose,
      }}
      primaryButtonProps={{
        label: "Delete",
        trackingId: "delete-user-submit-button",
        isLoading: isLoadingRemoveUser,
        loadingText: "Deleting",
        variant: "button-danger",
        onClick: () => {
          removeUser(
            { remove: [user.id] },
            {
              onSuccess: () => {
                addToast({
                  message: `${user.name} has been deleted.`,
                  trackingId: "delete-user-success-toast",
                  variant: "primary",
                  timeoutMs: 10000,
                });
              },
              onError: () => {
                addToast({
                  message:
                    "An error occurred and the user was not deleted. Please try again.",
                  trackingId: "delete-user-error-toast",
                  variant: "danger",
                  timeoutMs: 10000,
                });
              },
              onSettled: () => onClose(),
            }
          );
        },
      }}
    >
      <Flex gap="m" alignItems="center">
        <Avatar iconName="alert-triangle" variant="danger" size="large" />
        <Body>Are you sure you want to remove {user.name}?</Body>
      </Flex>
    </Modal>
  );
};

export default RemoveCompanyUserModal;
