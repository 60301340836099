import { Alert } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import pluralize from "pluralize";

import { useIrFormsContext } from "../context/useIrForms";

export enum FilingApprovalAlertTrackingId {
  Banner = "ir-forms-filing-banner",
}

const FilingApprovalAlert = () => {
  const { totalFormsGenerated, isLoading, shouldShowFilingActions } =
    useIrFormsContext();
  const { formatWholeQuantity } = useCosmicLocalizationContext();

  return (
    !isLoading &&
    shouldShowFilingActions &&
    totalFormsGenerated > 0 && (
      <Alert
        variant="primary"
        trackingId={FilingApprovalAlertTrackingId.Banner}
      >
        There {pluralize("is", totalFormsGenerated)}{" "}
        {formatWholeQuantity(totalFormsGenerated)}{" "}
        {pluralize("form", totalFormsGenerated)} pending your approval for
        filing.
      </Alert>
    )
  );
};

export default FilingApprovalAlert;
