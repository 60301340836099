import { ActionButton } from "@taxbit-private/cosmic";

import { useGenerateReport } from "./useGenerateReport";
import { TaxReport } from "../taxReportTypes";

export enum GenerateReportTrackingId {
  Button = "generate-tax-report-button",
}

type GenerateReportButtonProps = {
  report: TaxReport;
};

const GenerateReportButton: React.FC<GenerateReportButtonProps> = ({
  report,
}) => {
  const { isGenerating, generateReport } = useGenerateReport(report);

  return (
    <ActionButton
      isLoading={isGenerating}
      label={isGenerating ? undefined : "Generate New Report"}
      iconName="refresh"
      trackingId={GenerateReportTrackingId.Button}
      onClick={() => generateReport()}
    />
  );
};

export default GenerateReportButton;
