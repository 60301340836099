import { isDefined } from "@taxbit-dashboard/commons";
import { DashboardFormType, EligibilityCounts } from "@taxbit-dashboard/rest";
import { FormsAggregates } from "@taxbit-dashboard/rest/src/types/api/ir-forms/irFormsRestApiTypes";
import { Big } from "big.js";

import { NOT_REQUIRED_COMPLETE_DATA_DOCUMENT_TYPES } from "../../../api/information-reporting/eligible-users/eligibleUsersApiTypes";

export type FormInformation = {
  original: number;
  correction: number;
  void: number;
  total: number;
  unfulfilled?: number;
  percentage: number;
  date?: string;
};

type FormInformationByType = {
  generated: FormInformation;
  filed?: FormInformation;
};

const getPercentage = (numerator: Big, denominator: Big) => {
  const percentage = numerator
    .div(denominator.gt(0) ? denominator : new Big(1))
    .times(100);

  const percentageToNumber = percentage.round().toNumber();

  if (percentage.lt(100) && percentageToNumber === 100) {
    // If the difference is small and percentage is rounded to 100, return 99
    // Example: 99.99999
    return 99;
  }

  return percentageToNumber;
};

const calculateFormInformation = ({
  original,
  correction,
  void: voidValue,
  date,
  totalToFulfill,
  unfulfilled,
}: {
  original: string;
  correction: string;
  void: string;
  date?: string;
  unfulfilled?: number;
  totalToFulfill?: number;
}): FormInformation => {
  const total = new Big(original).add(correction).add(voidValue);

  const unfulfilledCalculated = (() => {
    if (isDefined(unfulfilled)) {
      return unfulfilled;
    }

    if (isDefined(totalToFulfill)) {
      return new Big(totalToFulfill).minus(total).toNumber();
    }

    return undefined;
  })();

  const totalToFulfillCalculated = (() => {
    if (isDefined(totalToFulfill)) {
      return totalToFulfill;
    }

    if (isDefined(unfulfilled)) {
      return new Big(total).add(unfulfilled).toNumber();
    }

    return undefined;
  })();

  return {
    original: new Big(original).toNumber(),
    correction: new Big(correction).toNumber(),
    void: new Big(voidValue).toNumber(),
    total: total.toNumber(),
    date,
    unfulfilled: unfulfilledCalculated,
    percentage: isDefined(totalToFulfillCalculated)
      ? getPercentage(total, new Big(totalToFulfillCalculated))
      : 0,
  };
};

/**
 * A helper to convert form metadata into a format that can be used by the
 * FormCountInformation component. Calculates total, unfulfilled and percentage values.
 */
const toFormCountBreakdownInformation = ({
  formType,
  formsAggregates,
  eligibilityCounts,
  shouldFileForm = false,
}: {
  formType: DashboardFormType;
  formsAggregates?: FormsAggregates;
  eligibilityCounts?: EligibilityCounts;
  shouldFileForm?: boolean;
}): FormInformationByType | undefined => {
  if (!formsAggregates) {
    return undefined;
  }

  const eligibleAccounts = (() => {
    if (!isDefined(eligibilityCounts)) return undefined;

    if (NOT_REQUIRED_COMPLETE_DATA_DOCUMENT_TYPES.has(formType)) {
      return (
        eligibilityCounts.eligible.completeAccountData +
        eligibilityCounts.eligible.incompleteAccountData
      );
    }

    return eligibilityCounts.eligible.completeAccountData;
  })();

  const eligibleAccountsWithFormGenerated = (() => {
    if (!isDefined(eligibilityCounts)) return undefined;

    if (NOT_REQUIRED_COMPLETE_DATA_DOCUMENT_TYPES.has(formType)) {
      return (
        eligibilityCounts.eligible.completeAccountDataWithFormGenerated +
        eligibilityCounts.eligible.incompleteAccountDataWithFormGenerated
      );
    }

    return eligibilityCounts.eligible.completeAccountDataWithFormGenerated;
  })();

  const eligibiltAccountsWithoutFormGenerated =
    isDefined(eligibleAccounts) && isDefined(eligibleAccountsWithFormGenerated)
      ? eligibleAccounts - eligibleAccountsWithFormGenerated
      : undefined;

  const generated = calculateFormInformation({
    original: (
      formsAggregates.generated.original + formsAggregates.filed.original
    ).toString(),
    correction: (
      formsAggregates.generated.correction + formsAggregates.filed.correction
    ).toString(),
    void: (
      formsAggregates.generated.voided + formsAggregates.filed.voided
    ).toString(),
    date: formsAggregates.latestGeneratedTimestamp,
    unfulfilled: eligibiltAccountsWithoutFormGenerated,
  });

  let filedData = {};

  if (shouldFileForm) {
    filedData = {
      filed: calculateFormInformation({
        original: formsAggregates.filed.original.toString(),
        correction: formsAggregates.filed.correction.toString(),
        void: formsAggregates.filed.voided.toString(),
        date: formsAggregates.latestFiledTimestamp,
        totalToFulfill: generated.total,
      }),
    };
  }

  return {
    generated,
    ...filedData,
  };
};

export default toFormCountBreakdownInformation;
