import { useIsMutating } from "@tanstack/react-query";
import {
  DashboardMutationKey,
  createSingleInstanceHookContext,
  useDashboardStore,
  useLocalPageBanner,
} from "@taxbit-dashboard/commons";
import { EligibilityDataItem, EligibilityStatus } from "@taxbit-dashboard/rest";
import {
  getEnUsErrorEmptyStateProps,
  useCosmicTable,
  useSearchEmptyStateProps,
} from "@taxbit-private/cosmic";
import { useCallback, useMemo, useState } from "react";

import {
  OverrideMutationResponse,
  useCreateEligibilityQaPackage,
  useGetEligibilityAlerts,
  useGetEligibilityCounts,
  useOverrideAccountsEligibility,
} from "../../../../api/information-reporting/eligible-users/eligibilityApi";
import { OverrideEligibilityAction } from "../../../../api/information-reporting/eligible-users/eligibleUsersApiTypes";
import { useGenerateTaxForms } from "../../../../api/information-reporting/forms/formsApi";
import useIrDropdowns from "../../shared/useIrDropdowns";
import {
  actionToBannerVerbMap,
  actionToPrepositionMap,
  getOverrideErroredToastContent,
  getOverrideSucceededToastContent,
} from "../remove-from-scope/overrideEligibilityNotifications";
import useEligibilityTableColumns from "../table/useEligibilityTableColumns";
import useEligibilityTableData from "../table/useEligibilityTableData";

enum EligibilityDataTableTrackingId {
  CosmicTable = "eligibility-data-table",
}

const useEligibilityData = () => {
  const [selectedRows, setSelectedRows] = useState<EligibilityDataItem[]>([]);
  // We allow up to 10k account IDs in this filter
  // Therefore we can't put it directly in the URL and we need to keep in in a state
  const [accountExternalIdsFilter, setAccountExternalIdsFilter] =
    useState<string>("");
  const addToast = useDashboardStore((state) => state.addToast);

  const clearAccountExternalIdsFilter = useCallback(() => {
    setAccountExternalIdsFilter("");
  }, [setAccountExternalIdsFilter]);
  const formDropdownData = useIrDropdowns(
    false,
    clearAccountExternalIdsFilter,
    clearAccountExternalIdsFilter
  );
  const { currentYear: formDate, currentForm: formType } = formDropdownData;

  const { data: eligibilityAlerts, isError: isEligibilityAlertsError } =
    useGetEligibilityAlerts({ formDate, formType });

  const {
    data: eligibilityCounts,
    isLoading: isEligibilityCountsLoading,
    isError: isEligibilityCountsError,
  } = useGetEligibilityCounts({ formDate, formType });

  const {
    data: eligibilityData,
    isLoading: isEligibilityDataLoading,
    isError: isEligibilityDataError,
    ...eligibleTableData
  } = useEligibilityTableData({
    accountExternalIdsFilter,
    setAccountExternalIdsFilter,
  });

  const isLoading = isEligibilityCountsLoading || isEligibilityDataLoading;
  const items = useMemo(
    () => eligibilityData?.items ?? [],
    [eligibilityData?.items]
  );

  const onOverrideSettle = useCallback(
    (action: OverrideEligibilityAction) =>
      (data?: OverrideMutationResponse) => {
        const errorCount = data?.errorCount ?? 0;
        const totalCount = data?.totalCount ?? 0;
        if (errorCount > 0) {
          addToast(
            getOverrideErroredToastContent(errorCount, totalCount, action)
          );
        } else {
          addToast(getOverrideSucceededToastContent(action));
        }
      },
    [addToast]
  );

  const { mutate: handleAddAccountsToScope } = useOverrideAccountsEligibility({
    formDate,
    formType,
    onSettled: onOverrideSettle(OverrideEligibilityAction.Add),
    action: OverrideEligibilityAction.Add,
  });

  const { mutate: handleRemoveAccountsFromScope } =
    useOverrideAccountsEligibility({
      formDate,
      formType,
      onSettled: onOverrideSettle(OverrideEligibilityAction.Remove),
      action: OverrideEligibilityAction.Remove,
    });

  const isAddingAccountsToScope =
    useIsMutating({
      mutationKey: [
        DashboardMutationKey.EligibilityOverride,
        { formDate, formType, action: OverrideEligibilityAction.Add },
      ],
    }) > 0;

  const isRemovingAccountsFromScope =
    useIsMutating({
      mutationKey: [
        DashboardMutationKey.EligibilityOverride,
        { formDate, formType, action: OverrideEligibilityAction.Remove },
      ],
    }) > 0;

  const { mutate: createEligibilityPackage, isLoading: isCreatingQaPackage } =
    useCreateEligibilityQaPackage({
      formDate,
      formType,
    });

  const { mutate: generateTaxForms } = useGenerateTaxForms({
    ...eligibleTableData.urlParams,
    formType,
    formDate,
  });

  const isGeneratingTaxForms =
    useIsMutating({
      mutationKey: [DashboardMutationKey.IrFormsBatch, { formDate, formType }],
    }) > 0;

  const shouldDisableControls =
    isRemovingAccountsFromScope ||
    isAddingAccountsToScope ||
    isCreatingQaPackage ||
    isGeneratingTaxForms ||
    eligibleTableData.shouldDisableControls;

  const columns = useEligibilityTableColumns({ formType });
  const memoizedGetRowKey = useCallback(
    ({ accountId }: EligibilityDataItem) => accountId,
    []
  );

  const searchEmptyStateProps = useSearchEmptyStateProps();

  const irEligibilityTableProps = useCosmicTable({
    trackingId: EligibilityDataTableTrackingId.CosmicTable,
    getRowKey: memoizedGetRowKey,
    shouldShowAutomaticPlaceholders: true,
    rows: items,
    columns,
    onRowSelect: setSelectedRows,
    isLoading: shouldDisableControls,
    getIsRowSelectionDisabled: (row) =>
      row.status === EligibilityStatus.Pending,
    emptyStateProps: isEligibilityDataError
      ? getEnUsErrorEmptyStateProps({ entity: "eligibility data" })
      : searchEmptyStateProps,
  });

  useLocalPageBanner({
    shouldShow: isAddingAccountsToScope || isRemovingAccountsFromScope,
    bannerConfig: {
      shouldShowSpinner: true,
      message: (() => {
        const action = isAddingAccountsToScope
          ? OverrideEligibilityAction.Add
          : OverrideEligibilityAction.Remove;

        return `We are ${actionToBannerVerbMap[action]} accounts ${actionToPrepositionMap[action]} scope. Please wait a few moments.`;
      })(),
    },
  });
  useLocalPageBanner({
    shouldShow: isGeneratingTaxForms,
    bannerConfig: {
      shouldShowSpinner: true,
      message:
        "We are preparing to generate the tax forms. Please wait a few moments.",
    },
  });

  return {
    ...eligibleTableData,
    ...formDropdownData,
    eligibilityAlerts,
    eligibilityCounts,
    isLoading,
    isEligibilityAlertsError,
    isEligibilityCountsError,
    eligibilityDataItems: items,
    isEligibilityDataError,
    isEligibilityDataLoading,
    /**
     * Indicates whether we should disable the controls because we are loading new results or
     * removing accounts from scope.
     */
    shouldDisableControls,
    /**
     * Handler for when the user clicks the add to scope button.
     * Will add the selected rows to the scope.
     */
    handleAddAccountsToScope,
    /**
     * Handler for when the user clicks the remove from scope button.
     * Will remove the selected rows from the scope.
     */
    handleRemoveAccountsFromScope,
    /**
     * The selected rows in the table.
     */
    selectedItems: selectedRows,
    /**
     * Props for the eligibility table.
     */
    irEligibilityTableProps,
    /**
     * Toggles the selection of all rows in the eligibility table.
     */
    toggleAllSelectedItems:
      irEligibilityTableProps.tableInstance.toggleAllRowsSelected,
    /*
     * Will start QA package generation for the selected form, year and accounts.
     */
    createEligibilityPackage,
    /*
     * Will start tax form generation for the selected form, year and accounts (optional).
     */
    generateTaxForms,
  };
};

export const {
  useContextHook: useEligibilityDataContext,
  Context: EligibilityDataContext,
  Provider: EligibilityDataProvider,
} = createSingleInstanceHookContext(useEligibilityData, "useEligibilityData");
