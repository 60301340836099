import { useQuery } from "@tanstack/react-query";
import {
  createQueryMetaObject,
  DashboardQueryKey,
  unwrapPublicApiWrappedQuery,
  useTaxBitRest,
} from "@taxbit-dashboard/commons";
import { ExternalTransactionId } from "@taxbit-dashboard/rest";

export const useGetTransferInLots = ({
  externalTransactionId,
  shouldFetchTransferLots,
}: {
  externalTransactionId: ExternalTransactionId;
  shouldFetchTransferLots: boolean;
}) => {
  const restSdk = useTaxBitRest();

  const query = useQuery(
    [{ key: DashboardQueryKey.TransferInLots, externalTransactionId }],
    () => restSdk.transferInLots.get(externalTransactionId),
    {
      ...createQueryMetaObject(
        restSdk.transferInLots.buildPath(externalTransactionId)
      ),
      enabled: shouldFetchTransferLots,
    }
  );

  return unwrapPublicApiWrappedQuery(query);
};
