import {
  DashboardNavigationItem,
  UserPermission,
  AuthorizedView,
} from "@taxbit-dashboard/commons";

import ReportsPage from "./Reports.page";

export const navigationItem: DashboardNavigationItem = {
  href: "/reports",
  iconName: "file",
  label: "Reports",
  trackingId: "sidebar-navigation-reports-anchor",
  shouldShowRoute: ({ permissions }) =>
    permissions[UserPermission.ReadTaxReports],
} as const;

export const route = {
  path: "reports",
  children: [
    {
      path: "/",
      element: (
        <AuthorizedView permissions={[UserPermission.ReadTaxReports]}>
          <ReportsPage />
        </AuthorizedView>
      ),
    },
  ],
};
