import { Button, ScopedSearchBar } from "@taxbit-private/cosmic";
import { useMemo, useState } from "react";
import styled from "styled-components";

import { AccountsUrlParams } from "../../accountsUrlParams";
import { useAccountsUrlParams } from "../../useAccountsUrlParams";
import { useAccountsView } from "../../useAccountsView";
import { ACCOUNTS_TABLE_ROW_LABELS } from "../accountsTableRow";

export enum AccountsTableSearchBarTrackingId {
  SearchBar = "accounts-table-search-bar",
  SearchButton = "accounts-table-search-button",
}

type TextSearchParams = Pick<
  AccountsUrlParams,
  "name" | "email" | "accountId" | "alternateAccountId"
>;

type SearchParamOption = {
  key: keyof TextSearchParams;
  label: string;
};

type AccountsTableSearchBarProps = {
  isDisabled: boolean;
};

const SEARCH_PARAM_OPTIONS: SearchParamOption[] = [
  {
    key: "accountId",
    label: ACCOUNTS_TABLE_ROW_LABELS.full.externalId,
  },
  {
    key: "alternateAccountId",
    label: ACCOUNTS_TABLE_ROW_LABELS.full.alternateExternalId,
  },
  { key: "name", label: ACCOUNTS_TABLE_ROW_LABELS.full.name },
  {
    key: "email",
    label: ACCOUNTS_TABLE_ROW_LABELS.full.email,
  },
];

const AccountsTableSearchBar: React.FC<AccountsTableSearchBarProps> = ({
  isDisabled,
}) => {
  const { setFilters } = useAccountsUrlParams();
  const { view } = useAccountsView();
  const [searchKeyOption, setSearchKeyOption] = useState<SearchParamOption>(
    SEARCH_PARAM_OPTIONS[0]
  );

  const dropdownOptions = useMemo(() => {
    return SEARCH_PARAM_OPTIONS.filter(
      ({ key }) =>
        key !== "alternateAccountId" ||
        view.columns.includes("alternateExternalId")
    );
  }, [view]);

  const [searchValue, setSearchValue] = useState("");

  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setFilters({
      [searchKeyOption.key]: searchValue.trim(),
    });
    setSearchValue("");
  };

  const isSearchTooLong = searchValue.length > 100;

  const isSubmitDisabled = !searchValue || isSearchTooLong;

  const tooltipProps = isSearchTooLong
    ? { content: "Search cannot exceed 100 characters." }
    : undefined;

  return (
    <FlexForm onSubmit={handleSearchSubmit}>
      <FullWidthScopedSearchBar
        trackingId={AccountsTableSearchBarTrackingId.SearchBar}
        label="Search by account name, email, or ID"
        isLabelHidden={true}
        dropdownProps={{
          options: dropdownOptions,
          onChange: setSearchKeyOption,
          value: searchKeyOption,
          getOptionKey: ({ key }: SearchParamOption) => key,
          getOptionLabel: ({ label }: SearchParamOption) => label,
        }}
        inputProps={{
          value: searchValue,
          onTextChange: setSearchValue,
        }}
        isDisabled={isDisabled}
        size="small"
      />
      <Button
        trackingId={AccountsTableSearchBarTrackingId.SearchButton}
        label="Search"
        type="submit"
        size="small"
        isDisabled={isSubmitDisabled}
        tooltipProps={tooltipProps}
      />
    </FlexForm>
  );
};

const FlexForm = styled.form(
  ({ theme }) => `
  display: flex;
  gap: ${theme.spacing.m};
  align-items: center;
  flex-grow: 1;
`
);

const FullWidthScopedSearchBar = styled(ScopedSearchBar<SearchParamOption>)`
  flex-grow: 1;
`;

export default AccountsTableSearchBar;
