import { ToastConfig } from "@taxbit-dashboard/commons";

import { TOAST_TIMEOUT } from "../../../../../utils/toastTimeout";

export enum IrFormActionsToastTrackingId {
  DeleteSuccessToast = "ir-forms-delete-confirmation-modal-success-toast",
  DeleteErrorToast = "ir-forms-delete-confirmation-modal-error-toast",
  FilingSuccessToast = "ir-forms-filing-success-toast",
  FilingErrorToast = "ir-forms-filing-error-toast",
}

export const successDeleteToastContent: ToastConfig = {
  message: "Forms have been successfully deleted.",
  trackingId: IrFormActionsToastTrackingId.DeleteSuccessToast,
  timeoutMs: TOAST_TIMEOUT,
};

export const getDeleteErrorToastContent = ({
  errorCount,
  totalCount,
}: {
  errorCount: number;
  totalCount: number;
}): ToastConfig => ({
  message:
    totalCount > 1
      ? `There was an error and ${errorCount}/${totalCount} of the requested forms were not deleted. Please try again.`
      : `Failed to delete the form. Please try again.`,
  trackingId: IrFormActionsToastTrackingId.DeleteErrorToast,
  variant: "danger",
  timeoutMs: TOAST_TIMEOUT,
});

export const successFilingToastContent: ToastConfig = {
  message:
    "Filing successfully started. Track the status in the Active Jobs tab. You will receive a notification when the filing is complete.",
  trackingId: IrFormActionsToastTrackingId.FilingSuccessToast,
  timeoutMs: TOAST_TIMEOUT,
};

export const getErrorFilingToastContent = (message?: string): ToastConfig => ({
  message: message ?? "Failed to approve filing. Please try again.",
  trackingId: IrFormActionsToastTrackingId.FilingSuccessToast,
  variant: "danger",
  timeoutMs: TOAST_TIMEOUT,
});
