import {
  UseMutationOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import {
  DashboardMutationKey,
  DashboardQueryKey,
  createQueryMetaObject,
  isTestEnv,
  logError,
  sleep,
  unwrapPublicApiWrappedQuery,
  useDashboardFeatureFlags,
  useInvalidateQueries,
  useTaxBitRest,
} from "@taxbit-dashboard/commons";
import {
  DashboardFormType,
  EligibilityDataItem,
  EligibilityOverrideStatus,
  EligibilityStatus,
} from "@taxbit-dashboard/rest";

import {
  EligibilityDataParams,
  OverrideEligibilityAction,
  toEligibilityFindAccountsRequest,
} from "./eligibleUsersApiTypes";
import getIrParams from "../getIrParams";
import {
  AccountExternalIdsFilter,
  IrFormTypeDateParams,
  mapDashboardFormTypeToApiFormType,
} from "../irApiTypes";

export type OverrideMutationResponse = {
  errorCount: number;
  totalCount: number;
};

export const ELIGIBILITY_DATA_MAX_LIMIT = 10_000;
export const ELIGIBILITY_REFRESH_DELAY = isTestEnv() ? 100 : 3_000;

export const useGetEligibilityAlerts = ({
  formDate,
  formType,
}: IrFormTypeDateParams) => {
  const restSdk = useTaxBitRest();

  const query = useQuery(
    [DashboardQueryKey.EligibilityAlerts, formDate, formType],
    () =>
      restSdk.eligibility.alerts.get(
        formDate,
        mapDashboardFormTypeToApiFormType(formType)
      ),
    {
      ...createQueryMetaObject(restSdk.eligibility.alerts.buildPath()),
    }
  );

  return unwrapPublicApiWrappedQuery(query);
};

export const useGetEligibilityCounts = ({
  formDate,
  formType,
  isQueryEnabled,
}: IrFormTypeDateParams & { isQueryEnabled?: boolean }) => {
  const restSdk = useTaxBitRest();
  const apiParams = getIrParams({ formDate, formType });

  const query = useQuery(
    [DashboardQueryKey.EligibilityCounts, { formDate, formType }],
    () => restSdk.eligibility.counts.get(apiParams),
    {
      ...createQueryMetaObject(restSdk.eligibility.counts.buildPath()),
      enabled: isQueryEnabled,
    }
  );

  return unwrapPublicApiWrappedQuery(query);
};

export const useGetEligibilityData = ({
  onMissingAccounts,
  ...params
}: EligibilityDataParams &
  AccountExternalIdsFilter & {
    onMissingAccounts: (
      requestIdsLength: number,
      totalAccountsNotFound: number
    ) => void;
  }) => {
  const restSdk = useTaxBitRest();
  const requestData = toEligibilityFindAccountsRequest(params);

  const query = useQuery(
    [DashboardQueryKey.EligibilityData, params],
    () => restSdk.eligibility.accounts.find.post(requestData),
    {
      ...createQueryMetaObject(restSdk.eligibility.accounts.find.buildPath()),
      keepPreviousData: true,
      onSuccess: (data) => {
        const totalAccountsNotFound = data?.meta?.totalAccountsNotFound;
        const requestIds = requestData?.filters.externalIds;
        if (totalAccountsNotFound && totalAccountsNotFound > 0 && requestIds) {
          onMissingAccounts(requestIds.length, totalAccountsNotFound);
        }
      },
    }
  );

  return unwrapPublicApiWrappedQuery(query);
};

export const useOverrideAccountsEligibility = ({
  formType,
  formDate,
  onSettled,
  action,
}: IrFormTypeDateParams & {
  onSettled?: UseMutationOptions<
    OverrideMutationResponse,
    Error,
    EligibilityDataItem[]
  >["onSettled"];
  action: OverrideEligibilityAction;
}) => {
  const restSdk = useTaxBitRest();
  const invalidateQueries = useInvalidateQueries();

  return useMutation<OverrideMutationResponse, Error, EligibilityDataItem[]>(
    async (items: EligibilityDataItem[]) => {
      const filteredItems = items.filter((item) => {
        if (action === OverrideEligibilityAction.Add) {
          return (
            item.status !== EligibilityStatus.Added &&
            item.status !== EligibilityStatus.Eligible &&
            (!!item.taxYearTotalTaxableTransactionCount || item.isFormData)
          );
        }

        return (
          item.status !== EligibilityStatus.Removed &&
          item.status !== EligibilityStatus.NonValidTaxCountry &&
          item.status !== EligibilityStatus.NotReportable &&
          item.status !== EligibilityStatus.Exempt
        );
      });
      if (filteredItems.length === 0) {
        return { errorCount: 0, totalCount: 0 };
      }

      const data = filteredItems.map((item) => {
        const overrideStatus = (() => {
          if (action === OverrideEligibilityAction.Add) {
            return item.status === EligibilityStatus.Removed
              ? EligibilityOverrideStatus.None
              : EligibilityOverrideStatus.AddToScope;
          }

          return item.status === EligibilityStatus.Added
            ? EligibilityOverrideStatus.None
            : EligibilityOverrideStatus.RemoveFromScope;
        })();

        return {
          documentType: mapDashboardFormTypeToApiFormType(formType),
          documentYear: formDate,
          accountId: item.accountId,
          overrideStatus,
        };
      });

      try {
        const res = await restSdk.eligibility.override.accounts.put({ data });
        await sleep(ELIGIBILITY_REFRESH_DELAY);
        // Invalidate query for the form that
        // is currently being edited
        await invalidateQueries([
          [DashboardQueryKey.EligibilityData, { formDate, formType }],
          [DashboardQueryKey.EligibilityCounts, { formDate, formType }],
        ]);

        return {
          errorCount: res.data.unprocessed.length,
          totalCount: filteredItems.length,
        };
      } catch (e) {
        logError({ message: "Failed to override eligibility", error: e });
        return {
          errorCount: filteredItems.length,
          totalCount: filteredItems.length,
        };
      }
    },
    {
      mutationKey: [
        DashboardMutationKey.EligibilityOverride,
        { formDate, formType, action },
      ],
      onSettled,
    }
  );
};

export const useCreateEligibilityQaPackage = ({
  formType,
  formDate,
}: IrFormTypeDateParams) => {
  const restSdk = useTaxBitRest();
  const { shouldForm1099BIncludeCostBasis } = useDashboardFeatureFlags();

  return useMutation((items: EligibilityDataItem[]) => {
    const data = (() => {
      const baseData = {
        documentType: formType,
        documentYear: formDate,
        accountIds: items.map(({ accountExternalId }) => accountExternalId),
      };

      if (formType === DashboardFormType.Irs1099B) {
        return {
          ...baseData,
          should1099bIncludeCostBasis: shouldForm1099BIncludeCostBasis,
        };
      }

      return baseData;
    })();

    return restSdk.eligibility.qaPackage.post({ data });
  });
};

export const useRecalculateEligibility = ({
  formType,
  formDate,
}: IrFormTypeDateParams) => {
  const restSdk = useTaxBitRest();

  return useMutation(
    () =>
      restSdk.eligibility.recalculate.post({
        year: formDate,
        formTypes: [formType],
      }),
    {
      ...createQueryMetaObject(restSdk.eligibility.recalculate.buildPath()),
      mutationKey: [
        DashboardMutationKey.EligibilityRecalc,
        { formType, formDate },
      ],
    }
  );
};
