import { Card, DenseRowGroup } from "@taxbit-private/cosmic";

import useDeliveryDeveloperSettingsRows, {
  DeliveryDeveloperSettingsTrackingId,
} from "./useDeliveryDeveloperSettingsRows";

const DeliveryDeveloperSettingsCard = () => {
  const deliveryConfigurationSettingsSections =
    useDeliveryDeveloperSettingsRows();
  return (
    <Card
      title="Form Delivery Settings"
      helpIconProps={{
        tooltipProps: {
          content: "Your forms are also available on the Forms page.",
        },
        trackingId:
          DeliveryDeveloperSettingsTrackingId.DeliveryConfigurationSettingsHelpIcon,
      }}
    >
      <DenseRowGroup sections={deliveryConfigurationSettingsSections} />
    </Card>
  );
};

export default DeliveryDeveloperSettingsCard;
