import {
  Box,
  TrimmedBoxContent,
  ContentSpinner,
  ContentErrorEmptyState,
  ContentEntityEmptyState,
  Flex,
  Icon,
  Body,
} from "@taxbit-private/cosmic";
import styled from "styled-components";

import ReportsTable from "./reports-table/ReportsTable";
import { useReportsDataContext } from "./useReportsData";

export enum ReportsTrackingId {
  ReportsSpinner = "reportsPageSpinner",
}

const Reports: React.FC = () => {
  const { hasNoReports, isLoading, isError } = useReportsDataContext();

  return (
    <Flex direction="column" gap="m">
      <Box>
        <TrimmedBoxContent trim="all">
          {isLoading ? (
            <ContentSpinner trackingId={ReportsTrackingId.ReportsSpinner} />
          ) : isError ? (
            <ContentErrorEmptyState entity="reports" />
          ) : hasNoReports ? (
            <ContentEntityEmptyState entity="reports" />
          ) : (
            <ReportsTable />
          )}
        </TrimmedBoxContent>
      </Box>
      {!isLoading && !isError && !hasNoReports && (
        <CenteredFlex gap="xs" alignItems="center">
          <Icon name="info" />
          <Body>
            Reports typically have a fifteen minute lag between data ingestion
            and that data being available.
          </Body>
        </CenteredFlex>
      )}
    </Flex>
  );
};

const CenteredFlex = styled(Flex)`
  margin: 0 auto;
`;

export default Reports;
