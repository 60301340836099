import { useIsMutating } from "@tanstack/react-query";
import {
  DashboardMutationKey,
  useDashboardFeatureFlags,
} from "@taxbit-dashboard/commons";

import {
  useGetEligibilityCounts,
  useRecalculateEligibility,
} from "../../../../api/information-reporting/eligible-users/eligibilityApi";
import { useGetFormsAggregates } from "../../../../api/information-reporting/forms/formsApi";
import useIrDropdowns from "../../shared/useIrDropdowns";
import toFormCountBreakdownInformation from "../toFormCountBreakdownInformation";

const useIrSummaryData = () => {
  const { hasEligibilityPageAccess, hasRecalculateEligibilityAccess } =
    useDashboardFeatureFlags();
  const formDropdownData = useIrDropdowns();
  const shouldFileForm = formDropdownData.currentForm.startsWith("IRS");
  const formSpecificParams = {
    formDate: formDropdownData.currentYear,
    formType: formDropdownData.currentForm,
  };

  const {
    data: formsAggregates,
    isLoading: isFormAggregatesLoading,
    isError: isFormAggregatesError,
  } = useGetFormsAggregates(formSpecificParams);

  const {
    data: eligibilityCounts,
    isFetching: isEligibilityCountsLoading,
    isError: isEligibilityCountsError,
  } = useGetEligibilityCounts({
    ...formSpecificParams,
    isQueryEnabled: hasEligibilityPageAccess,
  });

  const { mutate: recalculateEligibility } = useRecalculateEligibility({
    formType: formDropdownData.currentForm,
    formDate: formDropdownData.currentYear,
  });

  const isRecalculatingEligibility =
    useIsMutating({
      mutationKey: [
        DashboardMutationKey.EligibilityRecalc,
        {
          formType: formDropdownData.currentForm,
          formDate: formDropdownData.currentYear,
        },
      ],
    }) > 0;

  const shouldDisableControls = isRecalculatingEligibility;

  return {
    ...formDropdownData,
    eligibilityCounts,
    formMetadataCounts: toFormCountBreakdownInformation({
      formType: formDropdownData.currentForm,
      formsAggregates,
      eligibilityCounts,
      shouldFileForm,
    }),
    hasEligibilityPageAccess,
    hasRecalculateEligibilityAccess,
    isEligibilityCountsError,
    isEligibilityCountsLoading,
    isFormAggregatesLoading,
    isFormAggregatesError,
    isLoading: isFormAggregatesLoading || isEligibilityCountsLoading,
    shouldDisableControls,
    shouldFileForm,
    recalculateEligibility,
  };
};

export default useIrSummaryData;
