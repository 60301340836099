import {
  UserPermission,
  createSingleInstanceHookContext,
  useUserPermission,
  doesResponseErrorMatchStatusCode,
  useDashboardFeatureFlags,
  useGetPayers,
} from "@taxbit-dashboard/commons";

import {
  useGetAccountReportingProfiles,
  useGetAccountV2,
} from "../api/account/accountApi";
import { useGetAccountOwnerDetails } from "../api/account-owners/accountOwnersApi";
import { useGetKycTaxDocumentationStatus } from "../api/kyc-tax-documentation/kycTaxDocumentationApi";
import useAccountId from "../utils/useAccountId";

const useGetAccountOwnerTaxDocumentData = () => {
  const shouldUnmaskValue = useUserPermission(UserPermission.ReadTins);
  const { hasReportingProfile, showAccountDetailsReportingProfile } =
    useDashboardFeatureFlags();

  const {
    data: reportingProfiles,
    isError: isReportingProfilesError,
    isFetching: isReportingProfilesLoading,
    error: reportingProfilesError,
  } = useGetAccountReportingProfiles(
    hasReportingProfile && showAccountDetailsReportingProfile,
    shouldUnmaskValue
  );

  const {
    data: account,
    isLoading: isAccountLoading,
    isError: isAccountError,
    error: accountError,
  } = useGetAccountV2(shouldUnmaskValue);

  const {
    data: accountOwnerDetails,
    isInitialLoading: isAccountOwnerDetailsLoading,
    isError: isAccountOwnerDetailsError,
  } = useGetAccountOwnerDetails(
    account?.accountOwner.accountOwnerId,
    shouldUnmaskValue
  );

  const {
    data: taxDocumentationStatus,
    isFetching: isTaxDocumentationStatusLoading,
    isError: isTaxDocumentationStatusError,
  } = useGetKycTaxDocumentationStatus(account?.accountOwner.accountOwnerId);

  const { data: payers, isFetching: isPayersLoading } = useGetPayers();

  const accountId = useAccountId();
  const foundAccount = accountOwnerDetails?.accountOwner.accounts.find(
    (acct) => acct.accountId === accountId
  );

  const isValidTaxDocumentationStatus =
    accountOwnerDetails?.issues.length === 0 ||
    !accountOwnerDetails?.issues.some((issue) => !issue.resolvedAt);

  const isAccountNotFoundError = doesResponseErrorMatchStatusCode(
    accountError,
    404
  );

  const isReportingProfilesNotFound = doesResponseErrorMatchStatusCode(
    reportingProfilesError,
    404
  );

  const isLoading =
    isAccountLoading ||
    isAccountOwnerDetailsLoading ||
    isTaxDocumentationStatusLoading ||
    isReportingProfilesLoading ||
    isPayersLoading;

  return {
    account: foundAccount,
    accountOwner: accountOwnerDetails?.accountOwner,
    taxDocumentations: accountOwnerDetails?.latestQuestionnaires,
    issues: accountOwnerDetails?.issues,
    latestTaxDocumentationGroupNumber:
      accountOwnerDetails?.latestTaxDocumentationGroupNumber,
    taxDocumentationStatus,
    isLoading,
    isAccountNotFoundError,
    shouldUnmaskValue,
    isError: isAccountError || isAccountOwnerDetailsError,
    isTaxDocumentationStatusError,
    isValidTaxDocumentationStatus,
    isAccountLoading: isAccountLoading || isAccountOwnerDetailsLoading,
    reportingProfiles,
    isReportingProfilesError,
    isReportingProfilesLoading,
    isReportingProfilesNotFound,
    payers,
    isPayersLoading,
  };
};

export const {
  useContextHook: useAccountOwnerTaxDocumentContext,
  Context: AccountOwnerTaxDocumentContext,
  Provider: AccountOwnerTaxDocumentProvider,
} = createSingleInstanceHookContext(
  useGetAccountOwnerTaxDocumentData,
  "useGetAccountOwnerTaxDocumentData"
);
