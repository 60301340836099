import {
  useDashboardStore,
  useOrganizationPrefixedNavigation,
  useSetCurrentPageAsBackHref,
  Link,
  TRACKING_EXCLUDE_CLASS,
  renderExcludedSpan,
  useDashboardFeatureFlags,
} from "@taxbit-dashboard/commons";
import { IrForm } from "@taxbit-dashboard/rest";
import {
  Badge,
  Body,
  Flex,
  TableColumns,
  ColumnKey,
} from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useCallback, useMemo } from "react";

import {
  formTypeToLabelMap,
  formStatusToLabelMap,
} from "../../../../api/information-reporting/forms/formsApiTypes";

export enum FormsTableTrackingIds {
  AccountLink = "ir-forms-table-account-link",
  FormStatusHelpIcon = "ir-forms-table-form-status-help-icon",
}

const FORMS_STATUS_HELP_ICON_CONTENT = (
  <Flex direction="column" gap="xs">
    <Body>
      Generated: The Form has been generated and is available to be filed with
      the relevant tax authority.
    </Body>
    <Body>
      Filed: The final deliverable has been generated with this form’s data and
      ready to be, or filed with the relevant tax authority.
    </Body>
  </Flex>
);
export type FormsTableColumnKey = ColumnKey<IrForm>;

const useFormsTableColumns = () => {
  const setCurrentPageAsBackHref = useSetCurrentPageAsBackHref();
  const resetPageContext = useDashboardStore((store) => store.resetPageContext);
  const { buildOrganizationPrefixedPath } = useOrganizationPrefixedNavigation();
  const { formatDateTime } = useCosmicLocalizationContext();
  const { showAlternateExternalId } = useDashboardFeatureFlags();

  const onAccountLinkClick = useCallback(() => {
    // Start off page context for all nested views on a clean state.
    resetPageContext(["transactions", "transaction-details"]);
    setCurrentPageAsBackHref("account-details");
  }, [resetPageContext, setCurrentPageAsBackHref]);

  const getAccountLink = useCallback(
    (accountId: IrForm["accountId"]) => {
      return buildOrganizationPrefixedPath(`/accounts/${accountId}`);
    },
    [buildOrganizationPrefixedPath]
  );

  return useMemo((): TableColumns<IrForm> => {
    const columns: TableColumns<IrForm> = [
      {
        key: "accountExternalId",
        label: "Account ID",
        isContentFullWidth: true,
        shouldDisableSorting: true,
        renderCell: (accountExternalId, form) => (
          <Link
            to={getAccountLink(form.accountId)}
            trackingId={FormsTableTrackingIds.AccountLink}
            onClick={onAccountLinkClick}
            className={TRACKING_EXCLUDE_CLASS}
            label={accountExternalId ?? "Account Not Found"}
            variant="anchor-primary-inline"
          />
        ),
      },
      {
        key: "accountAlternateExternalId",
        label: "Alternate Account ID",
        isContentFullWidth: true,
        shouldDisableSorting: true,
      },
      {
        key: "name",
        label: "Name",
        shouldTruncate: true,
        minWidth: 128,
        shouldDisableSorting: true,
        renderCell: renderExcludedSpan,
      },
      {
        key: "type",
        label: "Type",
        shouldDisableSorting: true,
        renderCell: (type) => formTypeToLabelMap[type],
      },
      {
        key: "status",
        label: "Form Status",
        shouldDisableSorting: true,
        renderCell: (status) => (
          <Badge variant="success" label={formStatusToLabelMap[status]} />
        ),
        helpIconProps: {
          trackingId: FormsTableTrackingIds.FormStatusHelpIcon,
          tooltipProps: {
            content: FORMS_STATUS_HELP_ICON_CONTENT,
            appendTo: "body",
            isInteractive: false,
          },
        },
      },
      {
        key: "dateGenerated",
        label: "Date Generated",
        isContentFullWidth: true,
        renderCell: (date) =>
          formatDateTime({
            date,
            format: "DateTime",
          }),
      },
    ];
    return columns.filter(
      (x) => showAlternateExternalId || x.key !== "accountAlternateExternalId"
    );
  }, [
    formatDateTime,
    getAccountLink,
    onAccountLinkClick,
    showAlternateExternalId,
  ]);
};

export default useFormsTableColumns;
