import {
  formatTaxId,
  isDefined,
  useDashboardFeatureFlags,
} from "@taxbit-dashboard/commons";
import {
  payerDispositionMethodLabelMap,
  AccountOwner,
  Payer,
} from "@taxbit-dashboard/rest";
import { ActionSnippet } from "@taxbit-private/cosmic";
import {
  COSMIC_VALUE_PLACEHOLDER,
  useCosmicLocalizationContext,
} from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

import { AccountRowData } from "./useGetAccountDetails";
import {
  accountTypeMap,
  tinTypeMap,
} from "../../../../api/account/accountApiTypes";
import { taxClassificationMap } from "../../../../api/kyc-tax-documentation/kycTaxDocumentationApiTypes";
import { getIsoCountryName } from "../../../../utils/getIsoCountryName";
import maskData from "../../../../utils/maskData";
import maskTaxId from "../../../../utils/maskTaxId";
import useFormatUsdValue from "../../../../utils/useFormatUsdValue";
import AccountAddress from "../AccountAddress";
import InvalidCountry from "../InvalidCountry";
import TinBadge from "../TinBadge";
import VatBadge from "../VatBadge";

export enum AccountDetailsRowTrackingId {
  TaxbitAccountOwnerId = "account-details-taxbit-account-owner-id",
  AccountOwnerId = "account-details-account-owner-id",
  Tin = "account-details-tin",
  TaxbitAccountId = "account-details-taxbit-account-id",
  FilerId = "account-details-filer-id",
  AccountId = "account-details-account-id",
  AccountType = "account-details-account-type",
  VatId = "account-details-vat-id",
  BusinessRegistrationNumber = "account-details-business-registration-number",
  FinancialAccountId = "account-details-financial-account-id",
}

export const useAccountDetailsRows = ({
  account,
  accountOwner,
  shouldUnmaskValue,
  payers,
}: {
  shouldUnmaskValue: boolean;
  account?: AccountRowData;
  accountOwner?: AccountOwner;
  payers?: Payer[];
}) => {
  const { formatDateTime, formatBoolean } = useCosmicLocalizationContext();
  const formatUsdValue = useFormatUsdValue();
  const { showFilerDispositionMethod } = useDashboardFeatureFlags();

  return useMemo(() => {
    if (!account || !accountOwner) {
      return undefined;
    }

    const taxCountry = getIsoCountryName(accountOwner?.pii.taxCountryCode);
    const birthCountry = getIsoCountryName(accountOwner?.pii.birthCountry);
    const vatCountry = getIsoCountryName(accountOwner?.pii.vatCountry);

    const businessRegistrationCountry = getIsoCountryName(
      accountOwner?.pii.businessRegistrationCountry
    );

    const accountOwnerRows = {
      key: "account-owner",
      rows: [
        {
          label: "Account Owner ID",
          content: (
            <ActionSnippet
              contents={accountOwner.externalId}
              shouldCopy={true}
              trackingId={AccountDetailsRowTrackingId.AccountOwnerId}
            />
          ),
          key: "account-owner-id",
        },
        {
          label: "Date Created",
          content: formatDateTime({
            date: accountOwner.dateCreated,
          }),
          key: "date-created",
        },
        {
          label: "Date Modified",
          content: formatDateTime({
            date: accountOwner.dateModified,
          }),
          key: "date-modified",
        },
      ],
    };

    const accountOwnerPiiRows = {
      key: "account-owner-pii",
      rows: [
        {
          label: "Name",
          content: accountOwner.pii.name,
          key: "name",
        },
        {
          label: "Email",
          content: accountOwner.pii.email,
          key: "email",
        },
        {
          label: "Account Owner Type",
          content: accountOwner.accountOwnerType
            ? taxClassificationMap[accountOwner.accountOwnerType]
            : COSMIC_VALUE_PLACEHOLDER,
          key: "account-owner-type",
        },
        ...(accountOwner.accountOwnerType === "INDIVIDUAL"
          ? [
              {
                label: "Date of Birth",
                content: accountOwner.pii.birthDate
                  ? formatDateTime({
                      date: accountOwner.pii.birthDate,
                    })
                  : COSMIC_VALUE_PLACEHOLDER,
                key: "birth-date",
              },
              {
                label: "Birth City",
                content: accountOwner.pii.birthCity,
                key: "birth-city",
              },
              {
                label: "Birth Country",
                content:
                  birthCountry === "" ? <InvalidCountry /> : birthCountry,
                key: "birth-country",
              },
            ]
          : []),
        {
          label: "Address",
          content: accountOwner.pii.address ? (
            <AccountAddress address={accountOwner.pii.address} />
          ) : (
            COSMIC_VALUE_PLACEHOLDER
          ),
          key: "address",
        },
        {
          label: "Prefers Physical Mail",
          content: isDefined(accountOwner.prefersPhysicalMail)
            ? formatBoolean(accountOwner.prefersPhysicalMail)
            : COSMIC_VALUE_PLACEHOLDER,
          key: "prefers-physical-mail",
        },
        ...(isDefined(accountOwner.isTaxExempt)
          ? [
              {
                label: "Is Tax Exempt",
                content: formatBoolean(accountOwner.isTaxExempt),
                key: "is-tax-exempt",
              },
            ]
          : []),
        ...(isDefined(accountOwner.hasFatcaFilingRequirement)
          ? [
              {
                label: "Has Fatca Filing Requirement",
                content: formatBoolean(accountOwner.hasFatcaFilingRequirement),
                key: "has-fatca-filing-requirement",
              },
            ]
          : []),
        ...(isDefined(accountOwner.isSubjectToBackupWithholding)
          ? [
              {
                label: "Subject to Backup Withholding",
                content: formatBoolean(
                  accountOwner.isSubjectToBackupWithholding
                ),
                key: "subject-to-backup-withholding",
              },
            ]
          : []),
        ...(accountOwner.pii.businessRegistrationNumberMasked
          ? [
              {
                label: "Business Registration Number",
                content: (
                  <ActionSnippet
                    contents={
                      accountOwner.pii.businessRegistrationNumber ??
                      accountOwner.pii.businessRegistrationNumberMasked
                    }
                    shouldMask={shouldUnmaskValue}
                    maskContents={(value) =>
                      maskData(value) ?? COSMIC_VALUE_PLACEHOLDER
                    }
                    trackingId={
                      AccountDetailsRowTrackingId.BusinessRegistrationNumber
                    }
                  />
                ),
                key: "business-registration-number",
              },
            ]
          : []),
        ...(accountOwner.pii.businessRegistrationCountry
          ? [
              {
                label: "Business Registration Country",
                content:
                  businessRegistrationCountry === "" ? (
                    <InvalidCountry />
                  ) : (
                    businessRegistrationCountry
                  ),
                key: "business-registration-country",
              },
            ]
          : []),
        ...(accountOwner.pii.financialAccountIdMasked
          ? [
              {
                label: "Financial Account ID",
                content: (
                  <ActionSnippet
                    contents={
                      accountOwner.pii.financialAccountId ??
                      accountOwner.pii.financialAccountIdMasked
                    }
                    shouldMask={shouldUnmaskValue}
                    maskContents={(value) =>
                      maskData(value) ?? COSMIC_VALUE_PLACEHOLDER
                    }
                    trackingId={AccountDetailsRowTrackingId.FinancialAccountId}
                  />
                ),
                key: "financial-account-id",
              },
            ]
          : []),
        ...(accountOwner.pii.financialAccountHolderName
          ? [
              {
                label: "Financial Account Holder Name",
                content: accountOwner.pii.financialAccountHolderName,
                key: "financial-account-holder-name",
              },
            ]
          : []),
      ],
    };

    const tinValidationRows = {
      key: "tin-validation",
      rows: [
        {
          label: "Tax Country",
          content: taxCountry === "" ? <InvalidCountry /> : taxCountry,
          key: "tax-country",
        },
        {
          label: "TIN",
          content: (
            <ActionSnippet
              contents={
                accountOwner.pii.tin
                  ? formatTaxId(accountOwner.pii.tin, accountOwner.pii.tinType)
                  : accountOwner.pii.maskedTin
              }
              shouldMask={shouldUnmaskValue}
              maskContents={(value) =>
                maskTaxId(value, accountOwner.pii.tinType) ??
                COSMIC_VALUE_PLACEHOLDER
              }
              trackingId={AccountDetailsRowTrackingId.Tin}
            />
          ),
          key: "tin",
        },
        ...(accountOwner.pii.tinType
          ? [
              {
                label: "TIN Type",
                content: tinTypeMap[accountOwner.pii.tinType],
                key: "tin-type",
              },
            ]
          : []),
        ...(accountOwner.validation.irsTinMatch?.validationResult &&
        (accountOwner?.pii.taxCountryCode === "US" ||
          taxCountry === "" ||
          !taxCountry)
          ? [
              {
                label: "US TIN Status",
                content: (
                  <TinBadge
                    tinValidationStatus={
                      accountOwner.validation.irsTinMatch.validationResult
                    }
                  />
                ),
                key: "us-tin-status",
              },
              {
                label: "US TIN Verification Date",
                content: accountOwner.validation.irsTinMatch
                  ?.validationResponseDate
                  ? formatDateTime({
                      date: accountOwner.validation.irsTinMatch
                        .validationResponseDate,
                    })
                  : COSMIC_VALUE_PLACEHOLDER,
                key: "us-tin-verification-date",
              },
            ]
          : []),
      ],
    };

    const vatValidationRows = {
      key: "vat-validation",
      rows: [
        ...(vatCountry
          ? [
              {
                label: "VAT Country",
                content: vatCountry === "" ? <InvalidCountry /> : vatCountry,
                key: "vat-country",
              },
            ]
          : []),
        {
          label: "VAT Number",
          content: (
            <ActionSnippet
              contents={accountOwner.pii.vatId ?? accountOwner.pii.vatIdMasked}
              shouldMask={shouldUnmaskValue}
              maskContents={(value) =>
                maskData(value) ?? COSMIC_VALUE_PLACEHOLDER
              }
              trackingId={AccountDetailsRowTrackingId.VatId}
            />
          ),
          key: "vat-number",
        },
        ...(accountOwner.validation.vat?.validationResult
          ? [
              {
                label: "VAT Number Status",
                content: (
                  <VatBadge
                    vatValidationStatus={
                      accountOwner.validation.vat.validationResult
                    }
                  />
                ),
                key: "vat-number-status",
              },
            ]
          : []),
      ],
    };

    const accountRows = {
      key: "account",
      rows: [
        {
          label: "Account ID",
          content: (
            <ActionSnippet
              contents={account.externalId}
              shouldCopy={true}
              trackingId={AccountDetailsRowTrackingId.AccountId}
            />
          ),
          key: "account-id",
        },
        {
          label: "Date Created",
          content: formatDateTime({
            date: account.dateCreated,
          }),
          key: "date-created",
        },
        {
          label: "Date Modified",
          content: formatDateTime({
            date: account.dateModified,
          }),
          key: "date-modified",
        },
      ],
    };

    const accountDataRows = {
      key: "account-data",
      rows: [
        {
          label: "Filer ID",
          content: (
            <ActionSnippet
              contents={account.payerId}
              shouldCopy={true}
              trackingId={AccountDetailsRowTrackingId.FilerId}
            />
          ),
          key: "filer-id",
        },
        {
          label: "Filer Name",
          content: payers?.find((payer) => payer.payerId === account.payerId)
            ?.payerName,
          key: "filer-name",
        },
        ...(account.accountType
          ? [
              {
                label: "Account Type",
                content: accountTypeMap[account.accountType],
                key: "account-type",
              },
            ]
          : []),
        ...(account.establishmentDate
          ? [
              {
                label: "Establishment Date",
                content: formatDateTime({
                  date: account.establishmentDate,
                }),
                key: "establishment-date",
              },
            ]
          : []),
        ...(account.mostRecentFairMarketValue
          ? [
              {
                label: "Fair Market Value",
                content: formatUsdValue(account.mostRecentFairMarketValue),
                key: "fair-market-value",
              },
            ]
          : []),
        ...(showFilerDispositionMethod
          ? [
              {
                label: "Disposition Method",
                content: account.dispositionMethod
                  ? payerDispositionMethodLabelMap[account.dispositionMethod]
                  : COSMIC_VALUE_PLACEHOLDER,
                key: "disposition-method",
              },
            ]
          : []),
      ],
    };

    return {
      accountOwnerData: [
        accountOwnerRows,
        accountOwnerPiiRows,
        tinValidationRows,
        vatValidationRows,
      ],
      accountData: [accountRows, accountDataRows],
    };
  }, [
    account,
    formatBoolean,
    formatDateTime,
    shouldUnmaskValue,
    formatUsdValue,
    accountOwner,
    payers,
    showFilerDispositionMethod,
  ]);
};
