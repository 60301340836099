import {
  ToastConfig,
  useDashboardFeatureFlags,
  useDashboardStore,
} from "@taxbit-dashboard/commons";
import pluralize from "pluralize";
import { useState } from "react";

import { useGenerateEligibilityExport } from "../../../../api/information-reporting/eligible-users/eligibilityExportApi";
import { TOAST_TIMEOUT } from "../../../../utils/toastTimeout";
import {
  ExportEligibilityTableModalForm,
  exportEligibilityTableModalFormDefaultValues,
} from "../context/exportEligibilityTableModalFormTypes";
import { useEligibilityDataContext } from "../context/useEligibilityData";

export enum DownloadEligibilityTableTrackingId {
  ErrorToast = "irEligibilityTableExportErrorToast",
  SuccessToast = "irEligibilityTableExportSuccessToast",
  InfoToast = "irEligibilityTableExportInfoToast",
  CloseButton = "irEligibilityTableExportCloseButton",
  CancelButton = "irEligibilityTableExportCancelButton",
  SubmitButton = "irEligibilityTableExportSubmitButton",
  DataValidationReportCheckbox = "irEligibilityTableExportDataValidationReportCheckbox",
  FormDataReportCheckbox = "irEligibilityTableExportFormDataReportCheckbox",
  AccountDataReportCheckbox = "irEligibilityTableExportAccountDataReportCheckbox",
}

const exportErroredToastContent = {
  message: "Eligibility report failed to export. Please try again.",
  trackingId: DownloadEligibilityTableTrackingId.ErrorToast,
  variant: "danger",
  timeoutMs: TOAST_TIMEOUT,
} as const;

const getExportStartedToastContent = (reportCount: number): ToastConfig => ({
  message: `Your eligibility ${pluralize("report", reportCount)} ${pluralize("is", reportCount)} being exported.`,
  trackingId: DownloadEligibilityTableTrackingId.InfoToast,
  timeoutMs: TOAST_TIMEOUT,
});

const useEligibilityTableExport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const addToast = useDashboardStore((store) => store.addToast);
  const { showAlternateExternalId } = useDashboardFeatureFlags();

  const {
    urlParams,
    currentForm,
    currentYear,
    shouldDisableControls,
    isEligibilityDataError,
    accountExternalIdsFilter,
  } = useEligibilityDataContext();

  const { mutate: generateEligibilityExport } = useGenerateEligibilityExport();

  const handleSubmit = (
    formData: ExportEligibilityTableModalForm = exportEligibilityTableModalFormDefaultValues
  ) => {
    const reportCount = 1 + Object.values(formData).filter(Boolean).length;

    setIsLoading(true);
    addToast(getExportStartedToastContent(reportCount));

    generateEligibilityExport(
      {
        ...formData,
        ...urlParams,
        formDate: currentYear,
        formType: currentForm,
        accountExternalIdsFilter,
        includeAlternateAccountId: showAlternateExternalId,
      },
      {
        onError: () => {
          setIsLoading(false);
          addToast(exportErroredToastContent);
        },
        onSuccess: () => {
          setIsLoading(false);
        },
      }
    );
  };

  return {
    isLoading,
    isDisabled: isLoading || shouldDisableControls || isEligibilityDataError,
    onClick: handleSubmit,
  };
};

export default useEligibilityTableExport;
