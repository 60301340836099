import {
  DashboardAppRoutePath,
  useOrganizationPrefixedNavigation,
} from "@taxbit-dashboard/commons";
import { TopNavigation as CosmicTopNavigation } from "@taxbit-private/cosmic";
import styled from "styled-components";

import TopNavigationActionMenu from "./action-menu/TopNavigationActionMenu";
import { TopNavigationActionMenuContextProvider } from "./action-menu/useTopNavigationActionMenu";
import useTenantBrandingOverrides from "../../../../hooks/useTenantBrandingOverrides";
import NotificationsCenter from "../../../notifications/center/NotificationsCenter";

const TopNavigation: React.FC = () => {
  const { prefixedNavigate } = useOrganizationPrefixedNavigation();

  const { logoElement } = useTenantBrandingOverrides();

  return (
    <TopBar
      trackingId="top-navigation"
      appTitle="Information Reporting"
      logoElement={logoElement}
      onLogoClick={() => prefixedNavigate({ to: DashboardAppRoutePath.Launch })}
      utilityElements={
        <>
          <TopNavigationActionMenuContextProvider>
            <TopNavigationActionMenu />
          </TopNavigationActionMenuContextProvider>
          <NotificationsCenter />
        </>
      }
    />
  );
};

const TopBar = styled(CosmicTopNavigation)(
  ({ theme }) => `
  position: fixed;
  z-index: ${theme.zIndex.sideBarNav};
`
);

export default TopNavigation;
