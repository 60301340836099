import { TaxReportStatus } from "@taxbit-dashboard/rest";
import { ErrorIcon, Flex } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";

import { TaxReport } from "../taxReportTypes";
import { useReportsDataContext } from "../useReportsData";

export enum ReportDateWithErrorIconTrackingId {
  Icon = "tax-report-error-icon",
}

const ReportDateWithErrorIcon: React.FC<{ report: TaxReport }> = ({
  report,
}) => {
  const { formatDateTime } = useCosmicLocalizationContext();

  const { getReportStatus } = useReportsDataContext();
  const reportStatus = getReportStatus(report);

  const lastFailed = formatDateTime({
    date: reportStatus?.lastFailed,
    format: "DateTime",
  });

  return (
    <Flex alignItems="center" gap="s">
      <span>{report.date}</span>
      {reportStatus?.status === TaxReportStatus.Failed && (
        <ErrorIcon
          trackingId={ReportDateWithErrorIconTrackingId.Icon}
          tooltipProps={{
            content: `This report failed to generate on ${lastFailed}. Please try again, or contact Taxbit support if the issue persists.`,
            appendTo: "body",
            isInteractive: false,
          }}
        />
      )}
    </Flex>
  );
};

export default ReportDateWithErrorIcon;
