import {
  AccountOwnerDetailsTaxDocumentationIssueType,
  KycTaxDocumentType,
} from "@taxbit-dashboard/rest";

import {
  resubmissionOrCuringText,
  resubmissionText,
  treatyCountryMismatchActionRequiredText,
  usIndiciaText,
} from "./actionRequiredTexts";
import {
  addressResubmissionOrCuringDescriptionText,
  expiredFormDescriptionText,
  getAddressDescriptionText,
  treatyCountryMismatchDescriptionText,
  usIndiciaDescriptionText,
} from "./descriptionTexts";
import {
  HelpArticleLinkTrackingId,
  curingDocumentationLink,
  treatyCountryMismatchCuringDocumentationLink,
} from "./helpArticleLinks";
import {
  getCuringDocumentationObtainedText,
  getTreatyCountryMismatchDocumentationObtainedText,
  getNewTaxDocumentationSubmittedText,
} from "./resolutionTexts";

export const getIssueTextsByIssueType = (
  documentType: KycTaxDocumentType,
  hasCuring: boolean
) => ({
  [AccountOwnerDetailsTaxDocumentationIssueType.W8benePoBoxPermanentAddress]: {
    descriptionText: addressResubmissionOrCuringDescriptionText,
    actionRequired: resubmissionOrCuringText,
    resolutionText: hasCuring
      ? getCuringDocumentationObtainedText({
          href: curingDocumentationLink,
          trackingId: HelpArticleLinkTrackingId.CuringDocumentation,
        })
      : getNewTaxDocumentationSubmittedText(),
  },
  [AccountOwnerDetailsTaxDocumentationIssueType.ExpiredForm]: {
    descriptionText: expiredFormDescriptionText,
    actionRequired: resubmissionText,
    resolutionText: getNewTaxDocumentationSubmittedText(),
  },
  [AccountOwnerDetailsTaxDocumentationIssueType.UsPermanentAddress]: {
    descriptionText: getAddressDescriptionText(documentType),
    actionRequired: resubmissionText,
    resolutionText: getNewTaxDocumentationSubmittedText(),
  },
  [AccountOwnerDetailsTaxDocumentationIssueType.W8beneCareOf]: {
    descriptionText: addressResubmissionOrCuringDescriptionText,
    actionRequired: resubmissionOrCuringText,
    resolutionText: hasCuring
      ? getCuringDocumentationObtainedText({
          href: curingDocumentationLink,
          trackingId: HelpArticleLinkTrackingId.CuringDocumentation,
        })
      : getNewTaxDocumentationSubmittedText(),
  },
  [AccountOwnerDetailsTaxDocumentationIssueType.UsIndicia]: {
    descriptionText: usIndiciaDescriptionText,
    actionRequired: usIndiciaText,
    resolutionText: getCuringDocumentationObtainedText({
      href: curingDocumentationLink,
      trackingId: HelpArticleLinkTrackingId.CuringDocumentation,
    }),
  },
  [AccountOwnerDetailsTaxDocumentationIssueType.W8benPoBoxPermanentAddress]: {
    descriptionText: getAddressDescriptionText(KycTaxDocumentType.W8Ben),
    actionRequired: resubmissionText,
    resolutionText: getNewTaxDocumentationSubmittedText(),
  },
  [AccountOwnerDetailsTaxDocumentationIssueType.W8benCareOf]: {
    descriptionText: getAddressDescriptionText(KycTaxDocumentType.W8Ben),
    actionRequired: resubmissionText,
    resolutionText: getNewTaxDocumentationSubmittedText(),
  },
  [AccountOwnerDetailsTaxDocumentationIssueType.TreatyCountryMismatch]: {
    descriptionText: treatyCountryMismatchDescriptionText,
    actionRequired: treatyCountryMismatchActionRequiredText,
    resolutionText: hasCuring
      ? getTreatyCountryMismatchDocumentationObtainedText({
          href: treatyCountryMismatchCuringDocumentationLink,
          trackingId:
            HelpArticleLinkTrackingId.TreatyCountryMismatchCuringDocumentation,
        })
      : getNewTaxDocumentationSubmittedText({ isWarning: true }),
  },
});
