import { ActionButton } from "@taxbit-private/cosmic";

import { useDownloadReport } from "./useDownloadReport";
import { TaxReport } from "../taxReportTypes";

export enum DownloadReportTrackingId {
  Button = "download-irw-report-btn",
}

type DownloadReportButtonProps = {
  report: TaxReport;
};

const DownloadReportButton: React.FC<DownloadReportButtonProps> = ({
  report,
}) => {
  const { isLoading, onReportClick, isDisabled } = useDownloadReport(report);

  return (
    <ActionButton
      isLoading={isLoading}
      isDisabled={isDisabled}
      label={isLoading ? undefined : "Download"}
      iconName="download"
      trackingId={DownloadReportTrackingId.Button}
      onClick={onReportClick}
    />
  );
};

export default DownloadReportButton;
