import {
  AccountOwnerIssue,
  KycTaxDocumentType,
  KycTaxDocumentationAddress,
  KycTaxDocumentationDac7Status,
  LimitationOnBenefits,
  TaxDocumentStatusValidationResult,
  TypeOfIncome,
} from "@taxbit-dashboard/rest";
import { BadgeProps } from "@taxbit-private/cosmic";
import { U } from "ts-toolbelt";

export type TaxDocDetails = U.Strict<{
  documentType: KycTaxDocumentType;
  documentStatus: TaxDocumentStatusValidationResult;
  treatyClaimStatus?: "VALID" | "INVALID";
  name: string;
  taxClassification: string;
  submissionDate: string;
  expirationDate?: string;
  subjectToBackupWithholding?: boolean;
  issues?: AccountOwnerIssue[];
  warnings?: AccountOwnerIssue[];
  permanentAddress?: KycTaxDocumentationAddress;
  treatyCountry?: string;
}>;

export type Dac7Details = {
  interviewStatus: KycTaxDocumentationDac7Status;
  name?: string;
  isIndividual?: boolean;
  isEuResident?: boolean;
  submissionDate?: string;
  expirationDate?: string;
};

export const taxClassificationMap: Record<string, string> = {
  INDIVIDUAL: "Individual",
  PARTNERSHIP: "Partnership",
  SMLLC: "Single Member LLC",
  LLC_C: "LLC - C Corporation",
  LLC_P: "LLC - Partnership",
  LLC_S: "LLC - S Corporation",
  OTHER: "Other",
  SOLE_PROPRIETOR: "Sole Proprietor",
  C_CORPORATION: "C Corporation",
  S_CORPORATION: "S Corporation",
  TRUST_ESTATE: "Trust Estate",
  CORPORATION: "Corporation",
  SIMPLE_TRUST: "Simple Trust",
  COMPLEX_TRUST: "Complex Trust",
  GRANTOR_TRUST: "Grantor Trust",
  ESTATE: "Estate",
  CENTRAL_BANK_OF_ISSUE: "Central Bank of Issue",
  FOREIGN_GOVERNMENT_CONTROLLED_ENTITY: "Foreign Government Controlled Entity",
  FOREIGN_GOVERNMENT_INTEGRAL_PART: "Foreign Government Integral Part",
  TAX_EXEMPT_ORGANIZATION: "Tax Exempt Organization",
  PRIVATE_FOUNDATION: "Private Foundation",
  INTERNATIONAL_ORGANIZATION: "International Organization",
  ENTITY: "Entity",
};

export const kycTaxDocumentationDac7StatusToBadgePropsMap: Record<
  KycTaxDocumentationDac7Status,
  BadgeProps
> = {
  [KycTaxDocumentationDac7Status.Complete]: {
    label: "Complete",
    variant: "success",
  },
  [KycTaxDocumentationDac7Status.Incomplete]: {
    label: "Incomplete",
    variant: "danger",
  },
};

export const treatyClaimTypeOfIncomeMap: Record<TypeOfIncome, string> = {
  BUSINESS_PROFITS: "Business Profits",
  ROYALTIES_OTHER: "Royalties (other)",
  SERVICES: "Independent Personal Services",
};

export const treatyClaimLimitationOnBenefitsMap: Record<
  LimitationOnBenefits,
  string
> = {
  COMPANY_MEETS_BUSINESS_TEST: "Company meets business test",
  COMPANY_MEETS_DERIVATIVE_TEST: "Company meets derivative test",
  COMPANY_MEETS_EROSION_TEST: "Company meets erosion test",
  FAVORABLE_DETERMINATION: "Favorable determination",
  NO_LOB_ARTICLE: "No LOB article",
  GOVERNMENT: "Government",
  OTHER_ARTICLE_PARAGRAPH: "Other article/paragraph",
  OTHER_TAX_EXEMPT_ORGANIZATION: "Other tax-exempt organization",
  PUBLICLY_TRADED_CORPORATION: "Publicly traded corporation",
  SUBSIDIARY: "Subsidiary",
  TAX_EXEMPT_PENSION: "Tax-exempt pension",
};
