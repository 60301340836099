/**
 * Unique keys used to identify mutations within the React-Query system. These can be attached
 * to mutations when we need to determine if a mutation is already in flight. For more info, see docs:
 * https://tanstack.com/query/latest/docs/framework/react/guides/filters#mutation-filters
 */
enum DashboardMutationKey {
  EligibilityOverride = "EligibilityOverride",
  EligibilityRecalc = "EligibilityRecalc",
  GenerateTaxReport = "GenerateTaxReport",
  IrFormsBatch = "IrFormsBatch",
  IrFormsRescind = "IrFormsRescind",
  IrFormsFiling = "IrFormsFiling",
}

export default DashboardMutationKey;
