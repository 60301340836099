import { irFormTypeToDisplayNameMap } from "@taxbit-dashboard/commons";
import { Flex, Modal } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";

import { useIrFormsContext } from "../context/useIrForms";

enum ApproveFilingModalTrackingId {
  CloseButton = "approve-filing-modal-close-button",
  CancelButton = "approve-filing-modal-cancel-button",
  SubmitButton = "approve-filing-modal-submit-button",
  HelpIcon = "approve-filing-modal-help-icon",
  PopulationRadioGroup = "approve-filing-modal-population-radio-group",
  DataComparisonCheckbox = "approve-filing-modal-data-comparison-checkbox",
}

type ApproveFilingModalProps = {
  onClose: () => void;
  isOpen: boolean;
};

const ApproveFilingModal = ({ onClose, isOpen }: ApproveFilingModalProps) => {
  const { handleSubmitFiling, totalFormsGenerated, currentForm, currentYear } =
    useIrFormsContext();
  const { formatWholeQuantity } = useCosmicLocalizationContext();

  return (
    <Modal
      title="Approve Filing"
      isOpen={isOpen}
      onClose={onClose}
      closeButtonTrackingId={ApproveFilingModalTrackingId.CloseButton}
      secondaryButtonProps={{
        label: "Cancel",
        trackingId: ApproveFilingModalTrackingId.CancelButton,
        onClick: onClose,
      }}
      primaryButtonProps={{
        onClick: () => {
          handleSubmitFiling();
          onClose();
        },
        label: "Confirm",
        trackingId: ApproveFilingModalTrackingId.SubmitButton,
        variant: "button-primary",
      }}
    >
      <Flex direction="column" gap="m">
        Approving filing will start the process to file all
        &quot;Generated&quot; {currentYear}{" "}
        {currentForm && irFormTypeToDisplayNameMap[currentForm]} forms (
        {formatWholeQuantity(totalFormsGenerated)}).
      </Flex>
    </Modal>
  );
};

export default ApproveFilingModal;
