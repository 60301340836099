import {
  ContentEmptyState,
  ContentErrorEmptyState,
  ContentSpinner,
  Divider,
} from "@taxbit-private/cosmic";
import { Fragment } from "react";

import { useActiveJobsDataContext } from "./active-jobs/useActiveJobsData";
import ActiveJobListItem from "./list-item/ActiveJobListItem";

export enum ActiveJobsListContentsTrackingId {
  Spinner = "active-jobs-list-contents-spinner",
}

const MAX_DISPLAYED_ACTIVE_JOBS = 10;

const ActiveJobsListContents: React.FC = () => {
  const { activeJobs, isLoading, isError } = useActiveJobsDataContext();

  if (isLoading) {
    return (
      <ContentSpinner trackingId={ActiveJobsListContentsTrackingId.Spinner} />
    );
  } else if (isError) {
    return <ContentErrorEmptyState />;
  } else if (activeJobs.length === 0) {
    return (
      <ContentEmptyState
        title="No active jobs right now"
        avatarProps={{
          iconName: "bell",
          variant: "secondary",
        }}
      />
    );
  } else {
    const truncatedActiveJobs = activeJobs.slice(0, MAX_DISPLAYED_ACTIVE_JOBS);

    return truncatedActiveJobs.map((notification, idx) => (
      <Fragment key={notification.notificationId}>
        <ActiveJobListItem notification={notification} />
        {idx !== truncatedActiveJobs.length - 1 && <Divider />}
      </Fragment>
    ));
  }
};

export default ActiveJobsListContents;
