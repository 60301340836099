import { TransactionsByDay } from "@taxbit-dashboard/rest";
import {
  Card,
  ContentEntityEmptyState,
  ContentErrorEmptyState,
  ContentSpinner,
  Flex,
} from "@taxbit-private/cosmic";
import {
  CosmicChartsTooltipContent,
  TimeSeriesChart,
  hasChartData,
} from "@taxbit-private/cosmic-charts";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useCallback } from "react";

import TransactionsChartDateRangePicker from "./TransactionsChartDateRangePicker";
import TransactionsTotal from "./TransactionsTotal";
import { useOverviewDataContext } from "../context/useOverviewData";

const TransactionsChart = () => {
  const {
    transactionsQuery: { isError, isFetching, data, meta },
  } = useOverviewDataContext();

  const { formatWholeQuantity, formatDateTime } =
    useCosmicLocalizationContext();

  const getDatapointTooltipContent = useCallback(
    ({ totalTxnsCreated, transactionDay }: TransactionsByDay) => {
      return (
        <CosmicChartsTooltipContent
          items={[
            {
              label: formatDateTime({ date: transactionDay }),
              value: formatWholeQuantity(totalTxnsCreated),
            },
          ]}
        />
      );
    },
    [formatDateTime, formatWholeQuantity]
  );

  const hasAnyTransactions = meta?.allTimeTotal !== 0;

  if (!hasAnyTransactions) {
    return undefined;
  }

  return (
    <Card
      title="Transactions by Transaction Date"
      utilityElement={<TransactionsChartDateRangePicker />}
    >
      {isFetching ? (
        <ContentSpinner />
      ) : isError ? (
        <ContentErrorEmptyState />
      ) : hasChartData(data) ? (
        <Flex padding="contentPadding" direction="column" gap="m">
          <TransactionsTotal />
          <TimeSeriesChart
            data={data}
            getDatapointUtcString={({ transactionDay }) => transactionDay}
            getDatapointValue={({ totalTxnsCreated }) => totalTxnsCreated}
            getDatapointTooltipContent={getDatapointTooltipContent}
          />
        </Flex>
      ) : (
        <ContentEntityEmptyState entity="transactions data" />
      )}
    </Card>
  );
};

export default TransactionsChart;
