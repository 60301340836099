import { uuidSchema } from "@taxbit-private/uuids";
import { z } from "zod";

import {
  TIN_STATUS,
  VAT_VALIDATION_RESULT,
  W_FORM_TAX_CLASSIFICATION,
  dateFiltersSchema,
  pageSchema,
} from "./sharedApiTypes";
import getPublicApiSuccessSchema from "../getPublicApiSuccessSchema";

// Fields that require validation because we have logic based on their value
export const POSSIBLE_TIN_STATUSES = [...TIN_STATUS, "UNHANDLED"] as const;
export const tinStatusSchema = z.enum(POSSIBLE_TIN_STATUSES).catch("UNHANDLED");
export type TinStatus = z.infer<typeof tinStatusSchema>;

export const POSSIBLE_VAT_STATUSES = [
  ...VAT_VALIDATION_RESULT,
  "ERROR",
  "UNHANDLED",
] as const;
export const vatStatusSchema = z.enum(POSSIBLE_VAT_STATUSES).catch("UNHANDLED");
export type VatStatus = z.infer<typeof vatStatusSchema>;

const EXTENDED_TAX_CLASSIFICATIONS = [
  "US_IRA_ROTH",
  "US_IRA_TRADITIONAL",
  "US_IRA_SEP",
  "US_IRA_SIMPLE",
  "SOLE_PROPRIETOR",
  "UNHANDLED",
] as const;

export const POSSIBLE_TAX_CLASSIFICATIONS = [
  ...W_FORM_TAX_CLASSIFICATION,
  ...EXTENDED_TAX_CLASSIFICATIONS,
] as const;

export const taxClassificationSchema = z
  .enum(POSSIBLE_TAX_CLASSIFICATIONS)
  .catch("UNHANDLED");
export type TaxClassification = z.infer<typeof taxClassificationSchema>;

// Hydrated account
export const usProfileSchema = z.object({
  taxClassification: taxClassificationSchema.optional(),
  taxCountryCodes: z.string().array().optional(),
  tinStatus: tinStatusSchema.optional(),
  taxDocumentationType: z.string().optional(),
  name: z.string().optional(),
});
export type UsProfile = z.infer<typeof usProfileSchema>;

export const globalProfileSchema = z.object({
  taxClassification: taxClassificationSchema.optional(),
  taxCountryCodes: z.string().array().optional(),
  vatStatus: vatStatusSchema.optional(),
  taxDocumentationType: z.string().optional(),
  name: z.string().optional(),
});

export const profileSchema = z.object({
  us: usProfileSchema.optional(),
  global: globalProfileSchema.optional(),
});
export type Profile = z.infer<typeof profileSchema>;

export const hydratedAccountSchema = z.object({
  id: uuidSchema,
  organizationId: uuidSchema,
  externalId: z.string(),
  alternateExternalId: z.string().optional(),
  dateCreated: z.string(),
  name: z.string().optional(),
  email: z.string().optional(),
  type: taxClassificationSchema.optional(),
  taxCountryCodes: z.array(z.string()).optional(),
  tinStatus: tinStatusSchema.optional(),
  vatStatus: vatStatusSchema.optional(),
  profile: profileSchema.optional(),
  filerId: z.string().optional(),
});
export type HydratedAccount = z.infer<typeof hydratedAccountSchema>;

// Response and request

export const searchHydratedAccountsResponse = getPublicApiSuccessSchema(
  z.array(hydratedAccountSchema)
);

const sortSchema = z.enum([
  "account.dateCreated",
  "-account.dateCreated",
  "account.name",
  "-account.name",
]);

export const accountFilters = z.object({
  externalId: z.string().optional(),
  alternateExternalId: z.string().optional(),
  name: z.string().optional(),
  email: z.string().optional(),
  tinStatuses: z.array(tinStatusSchema).optional(),
  vatStatuses: z.array(vatStatusSchema).optional(),
  dateCreated: dateFiltersSchema.optional(),
  types: z.array(taxClassificationSchema).optional(),
  filerIds: z.array(z.string()).optional(),
  taxCountryCodes: z.array(z.string()).optional(),
  matchMissingFiler: z.boolean().optional(),
});

export const usProfileFilters = z.object({
  name: z.string().optional(),
  taxClassifications: taxClassificationSchema.array().optional(),
  taxCountryCodes: z.string().array().optional(),
  tinStatuses: tinStatusSchema.array().optional(),
  taxDocumentationTypes: z.string().array().optional(),
});

export const globalProfileFilters = z.object({
  name: z.string().optional(),
  taxClassifications: taxClassificationSchema.array().optional(),
  taxCountryCodes: z.string().array().optional(),
  vatStatuses: vatStatusSchema.array().optional(),
  taxDocumentationTypes: z.string().array().optional(),
});

export const profileFilters = z.object({
  us: usProfileFilters.optional(),
  global: globalProfileFilters.optional(),
});

const hydratedAccountsFiltersSchema = z.object({
  account: accountFilters.optional(),
  profile: profileFilters.optional(),
});

export const searchHydratedAccountsParamSchema = z.object({
  filters: hydratedAccountsFiltersSchema.optional(),
  page: pageSchema.optional(),
  sort: sortSchema.optional(),
});
export type SearchHydratedAccountsParams = z.infer<
  typeof searchHydratedAccountsParamSchema
>;

export const hydratedAccountsFilterOptionsSchema =
  hydratedAccountsFiltersSchema;
export type HydratedAccountsFilterOptions = z.infer<
  typeof hydratedAccountsFilterOptionsSchema
>;

const exportMappingPathSchema = z.enum([
  "external_id",
  "alternate_external_id",
  "name",
  "date_created",
  "email",
  "type",
  "tin_status",
  "vat_status",
  "tax_country_codes",
  "filer_id",
  "profile.us.name",
  "profile.us.tax_classification",
  "profile.us.tax_country_codes",
  "profile.us.tin_status",
  "profile.us.tax_documentation_type",
  "profile.global.name",
  "profile.global.tax_classification",
  "profile.global.tax_country_codes",
  "profile.global.vat_status",
  "profile.global.tax_documentation_type",
]);

const exportMappingSchema = z.object({
  header: z.string(),
  path: exportMappingPathSchema,
});

export const exportHydratedAccountsParamsSchema = z.object({
  filters: hydratedAccountsFiltersSchema.optional(),
  sort: sortSchema.optional(),
  mappings: exportMappingSchema.array(),
});

export type ExportHydratedAccountsParams = z.infer<
  typeof exportHydratedAccountsParamsSchema
>;
