import { useIsMutating } from "@tanstack/react-query";
import {
  DashboardMutationKey,
  useDefinedCurrentCompanyUser,
} from "@taxbit-dashboard/commons";
import { useMemo } from "react";

import { useGenerateTaxReport } from "../../../api/taxReportsApi";
import { TaxReport } from "../taxReportTypes";
import { useReportsDataContext } from "../useReportsData";

export const useGenerateReport = (report: TaxReport) => {
  const currentCompanyUser = useDefinedCurrentCompanyUser();

  const { mutate: generateReport } = useGenerateTaxReport({
    requestData: {
      reportName: report.key,
      generatedBy: currentCompanyUser.id,
    },
    userFriendlyReportName: report.name,
  });

  const { isReportGenerationInProgress } = useReportsDataContext();
  const isRequestingGeneration =
    useIsMutating({
      mutationKey: [DashboardMutationKey.GenerateTaxReport, report.key],
    }) > 0;

  const isGenerating = useMemo(
    () => isReportGenerationInProgress(report) || isRequestingGeneration,
    [isReportGenerationInProgress, isRequestingGeneration, report]
  );

  return {
    generateReport,
    isGenerating,
  };
};
