import { useMutation, useQuery } from "@tanstack/react-query";
import {
  DashboardQueryKey,
  createQueryMetaObject,
  unwrapPublicApiWrappedQuery,
  useTaxBitRest,
} from "@taxbit-dashboard/commons";
import {
  ExportHydratedAccountsParams,
  SearchHydratedAccountsParams,
} from "@taxbit-dashboard/rest";

export const useGetHydratedAccounts = (
  params: SearchHydratedAccountsParams = {}
) => {
  const restSdk = useTaxBitRest();

  const query = useQuery(
    [DashboardQueryKey.HydratedAccountsSearch, { ...params }],
    () => restSdk.hydratedAccounts.search.post(params),
    {
      ...createQueryMetaObject(restSdk.hydratedAccounts.search.buildPath()),
      keepPreviousData: true,
      cacheTime: 0,
    }
  );

  return unwrapPublicApiWrappedQuery(query);
};

export const useGetTotalAccountsCount = () => {
  const query = useGetHydratedAccounts();

  return {
    ...query,
    data: query.meta?.page?.totalCount,
  };
};

export const useCreateHydratedAccountsExport = () => {
  const restSdk = useTaxBitRest();

  return useMutation(
    (params: ExportHydratedAccountsParams) => {
      return restSdk.hydratedAccounts.exports.post(params);
    },
    {
      ...createQueryMetaObject(restSdk.hydratedAccounts.exports.buildPath()),
    }
  );
};
