import { zodResolver } from "@hookform/resolvers/zod";
import {
  ToastConfig,
  hasDefinedValues,
  useDashboardStore,
} from "@taxbit-dashboard/commons";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import pluralize from "pluralize";
import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";

import { TOAST_TIMEOUT } from "../../../../../utils/toastTimeout";
import {
  formGenerationModalFormSchema,
  formGenerationModalFormDefaultValues,
  FormGenerationModalForm,
} from "../../context/formGenerationModalFormTypes";
import { useEligibilityDataContext } from "../../context/useEligibilityData";

export enum GenerateTaxFormsConfirmationModalTrackingId {
  PopulationRadioGroup = "ir-eligibility-generate-tax-forms-confirmation-modal-population-radio-group",
  DataComparisonCheckbox = "ir-eligibility-generate-tax-forms-confirmation-modal-data-comparison-checkbox",
  CloseButton = "ir-eligibility-generate-tax-forms-confirmation-modal-close-btn",
  CancelButton = "ir-eligibility-generate-tax-forms-confirmation-modal-cancel-btn",
  SubmitButton = "ir-eligibility-generate-tax-forms-confirmation-modal-submit-btn",
  SuccessToast = "ir-eligibility-generate-tax-forms-confirmation-modal-success-toast",
  ErrorToast = "ir-eligibility-generate-tax-forms-confirmation-modal-error-toast",
  HelpIcon = "ir-eligibility-generate-tax-forms-confirmation-modal-help-icon",
  EntirePopulationOption = "ir-eligibility-generate-tax-forms-confirmation-modal-entire-population-option",
  FilteredPopulationOption = "ir-eligibility-generate-tax-forms-confirmation-modal-filtered-population-option",
}

export const makeGenerateTaxFormsSuccessToastContent = (
  formsCount: number
): ToastConfig => ({
  message: `${pluralize("Form", formsCount)} ${pluralize("is", formsCount)} generating. Track the status in the Active Jobs tab. You will receive a notification when the ${pluralize("form", formsCount)} ${pluralize("is", formsCount)} available on the Forms page.`,
  trackingId: GenerateTaxFormsConfirmationModalTrackingId.SuccessToast,
  timeoutMs: TOAST_TIMEOUT,
});

export const makeGenerateTaxFormsErrorToastContent = (
  formsCount: number
): ToastConfig => ({
  message: `${pluralize("Form", formsCount)} failed to generate. Please try again.`,
  variant: "danger",
  trackingId: GenerateTaxFormsConfirmationModalTrackingId.ErrorToast,
  timeoutMs: TOAST_TIMEOUT,
});

const useGenerateTaxFormsConfirmationModal = ({
  shouldIgnoreSelectedItems = false,
  onClose,
}: {
  shouldIgnoreSelectedItems?: boolean;
  onClose: () => void;
}) => {
  const {
    accountExternalIdsFilter,
    urlParams: { formDate, formType, page, limit, ...filterParams },
    selectedItems,
    eligibilityCounts,
    totalCount: totalFilteredAccounts,
    shouldDisableControls,
    generateTaxForms,
    toggleAllSelectedItems,
  } = useEligibilityDataContext();
  const addToast = useDashboardStore((state) => state.addToast);

  const formGenerationFormMethods = useForm<FormGenerationModalForm>({
    resolver: zodResolver(formGenerationModalFormSchema),
    defaultValues: formGenerationModalFormDefaultValues,
  });

  const { formatWholeQuantity } = useCosmicLocalizationContext();

  const totalAccounts = shouldIgnoreSelectedItems
    ? eligibilityCounts?.total ?? 0
    : selectedItems.length;

  const formattedTotalAccounts = useMemo(() => {
    return ` (${formatWholeQuantity(totalAccounts)} ${pluralize(
      "account",
      totalAccounts
    )})`;
  }, [totalAccounts, formatWholeQuantity]);

  const populationRadioGroupOptions = useMemo(
    () => [
      {
        label: `Filtered population (${formatWholeQuantity(totalFilteredAccounts)} ${pluralize(
          "account",
          totalFilteredAccounts
        )})`,
        value: "filtered",
        trackingId:
          GenerateTaxFormsConfirmationModalTrackingId.FilteredPopulationOption,
      },
      {
        label: `Entire population${formattedTotalAccounts}`,
        value: "all",
        trackingId:
          GenerateTaxFormsConfirmationModalTrackingId.EntirePopulationOption,
      },
    ],
    [formattedTotalAccounts, formatWholeQuantity, totalFilteredAccounts]
  );

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      void formGenerationFormMethods.handleSubmit(
        ({ population, shouldSkipCompareData }) => {
          const totalForms = (() => {
            if (!shouldIgnoreSelectedItems) {
              return selectedItems.length;
            }

            if (population === "filtered") {
              return totalFilteredAccounts;
            }

            return eligibilityCounts?.total ?? 0;
          })();

          generateTaxForms(
            {
              shouldIncludeFilters:
                shouldIgnoreSelectedItems && population === "filtered",
              shouldCompareData: !shouldSkipCompareData,
              eligibilityItems: shouldIgnoreSelectedItems
                ? undefined
                : selectedItems,
              accountExternalIdsFilter,
            },
            {
              onSuccess: () => {
                addToast(makeGenerateTaxFormsSuccessToastContent(totalForms));
              },
              onError: () => {
                addToast(makeGenerateTaxFormsErrorToastContent(totalForms));
              },
            }
          );

          formGenerationFormMethods.reset();
          onClose();
          toggleAllSelectedItems(false);
        }
      )(e);
    },
    [
      accountExternalIdsFilter,
      addToast,
      eligibilityCounts?.total,
      formGenerationFormMethods,
      generateTaxForms,
      onClose,
      selectedItems,
      shouldIgnoreSelectedItems,
      toggleAllSelectedItems,
      totalFilteredAccounts,
    ]
  );

  return {
    formattedTotalAccounts,
    formGenerationFormMethods,
    handleSubmit,
    hasFiltersApplied:
      hasDefinedValues(filterParams) || accountExternalIdsFilter.length > 0,
    isLoading: shouldDisableControls,
    populationRadioGroupOptions,
  };
};

export default useGenerateTaxFormsConfirmationModal;
