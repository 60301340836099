import { GridGroup, useDashboardFeatureFlags } from "@taxbit-dashboard/commons";
import {
  Button,
  Card,
  ContentEntityEmptyState,
  ContentErrorEmptyState,
  ContentSpinner,
} from "@taxbit-private/cosmic";
import { useMemo, useState } from "react";

import useDac7InterviewDetailsRows from "./useDac7InterviewDetailsRows";
import useGetDac7InterviewDetails from "./useGetDac7InterviewDetails";
import TaxDocumentationDetailsModal from "../TaxDocumentationDetailsModal";

export enum Dac7InterviewDetailsTrackingId {
  Dac7InterviewDetailsSpinner = "dac7-interview-details-spinner",
  ViewDetailsBtn = "dac7-interview-details-view-details-btn",
}

const Dac7InterviewDetails: React.FC = () => {
  const { hasDac7AccountAccess, showAccountDetailsReportingProfile } =
    useDashboardFeatureFlags();
  const {
    isLoading,
    dac7Details,
    dps,
    shouldUnmaskValue,
    taxDocumentationStatus,
  } = useGetDac7InterviewDetails();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dac7InterviewDetailsRows = useDac7InterviewDetailsRows({
    dac7Details,
  });

  const cardContent = useMemo(() => {
    if (isLoading) {
      return (
        <ContentSpinner
          trackingId={
            Dac7InterviewDetailsTrackingId.Dac7InterviewDetailsSpinner
          }
        />
      );
    }

    if (!hasDac7AccountAccess) {
      return (
        <ContentEntityEmptyState entity="Digital Platform Seller (DPS) Questinnaire" />
      );
    }

    if (dac7InterviewDetailsRows) {
      return <GridGroup data={dac7InterviewDetailsRows} />;
    }

    return (
      <ContentErrorEmptyState entity="Digital Platform Seller (DPS) Questinnaire" />
    );
  }, [dac7InterviewDetailsRows, hasDac7AccountAccess, isLoading]);

  return (
    <>
      <Card
        title="Digital Platform Seller (DPS) Questinnaire"
        utilityElement={
          showAccountDetailsReportingProfile && (
            <Button
              variant="button-secondary"
              label="View details"
              trackingId={Dac7InterviewDetailsTrackingId.ViewDetailsBtn}
              isDisabled={!dps}
              onClick={() => {
                setIsModalOpen(true);
              }}
            />
          )
        }
      >
        {cardContent}
      </Card>
      {dps && (
        <TaxDocumentationDetailsModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          data={dps}
          shouldUnmaskValue={shouldUnmaskValue}
          taxDocStatus={taxDocumentationStatus}
        />
      )}
    </>
  );
};

export default Dac7InterviewDetails;
