import { TaxReportType } from "@taxbit-dashboard/rest";

export enum TaxReportDisplayType {
  Custom = "Custom",
  Accounts = "Accounts",
  Ingestion = "Ingestion",
  Reconciliation = "Reconciliation",
  TaxForms = "Tax Forms",
}

export type TaxReport = {
  key: TaxReportType;
  name: string;
  description: string;
  date: string;
  type: TaxReportDisplayType;
};
