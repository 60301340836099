import { fourDigitYearSchema } from "@taxbit-private/datetime";
import { uuidSchema } from "@taxbit-private/uuids";
import { z } from "zod";

import getPublicApiSuccessSchema from "../../getPublicApiSuccessSchema";
import {
  accountValidationsEligibilityStatusSchema,
  dashboardEligibilityFormStatusSchema,
  eligibilityStatusSchema,
} from "../eligibility/eligibilityRestApiTypes";
import { IrFormTypeApiParams } from "../irRestApiTypes";
import { dashboardFormTypeSchema } from "../shared/dashboardFormType";
import {
  OffsetPaginationApiParams,
  accountIdSchema,
  dateFiltersSchema,
  pageSchema,
} from "../sharedApiTypes";

const formsAggregatesSchema = z.object({
  documentType: dashboardFormTypeSchema,
  documentDate: z.number().transform((year) => fourDigitYearSchema.parse(year)),
  original: z.number(),
  correction: z.number(),
  voided: z.number(),
  total: z.number(),
  generated: z.object({
    original: z.number(),
    correction: z.number(),
    voided: z.number(),
  }),
  filed: z.object({
    original: z.number(),
    correction: z.number(),
    voided: z.number(),
  }),
  latestGeneratedTimestamp: z.string().optional(),
  latestFiledTimestamp: z.string().optional(),
});

export type FormsAggregates = z.infer<typeof formsAggregatesSchema>;

export const getFormsAggregatesResponseSchema = getPublicApiSuccessSchema(
  formsAggregatesSchema
);

export const getFormsSearchKeySchema = z.enum([
  "account_external_id",
  "name",
  "account_alternate_external_id",
]);
export type GetFormsSearchKey = z.infer<typeof getFormsSearchKeySchema>;

export const getFormsSortKeySchema = z.enum(["date_generated"]);
export type GetFormsSortKey = z.infer<typeof getFormsSortKeySchema>;

export const getFormsSortSchema = z.enum([
  ...getFormsSortKeySchema.options,
  ...getFormsSortKeySchema.options.map((opt) => `-${opt}` as const),
]);
export type GetFormsSort = z.infer<typeof getFormsSortSchema>;

export enum IrFormStatus {
  Generated = "GENERATED",
  Filed = "FILED",
}
export const irFormStatusSchema = z.nativeEnum(IrFormStatus);

export enum IrFormType {
  Original = "ORIGINAL",
  Correction = "CORRECTION",
  Void = "VOID",
}
export const irFormTypeSchema = z.nativeEnum(IrFormType);

export type GetIrFormsParams = IrFormTypeApiParams &
  OffsetPaginationApiParams & {
    "sort"?: GetFormsSort;
    "filters[account_external_id]"?: string;
    "filters[name]"?: string;
    "filters[status]"?: IrFormStatus[];
    "filters[type]"?: IrFormType[];
    "filters[date_generated][$gte]"?: string;
    "filters[date_generated][$lte]"?: string;
  };

export const revisionKeySchema = z.string().brand("RevisionKey");
export type RevisionKey = z.infer<typeof revisionKeySchema>;

const irFormSchema = z.object({
  id: uuidSchema,
  accountId: accountIdSchema,
  accountExternalId: z.string().optional(),
  accountAlternateExternalId: z.string().optional(),
  name: z.string().optional(),
  status: irFormStatusSchema,
  type: irFormTypeSchema,
  dateGenerated: z.string().datetime().optional(),
  documentDate: z.number().transform((year) => fourDigitYearSchema.parse(year)),
  documentType: dashboardFormTypeSchema,
  revisionKey: revisionKeySchema,
});

export type IrForm = z.infer<typeof irFormSchema>;

export const getIrFormsResponseSchema = getPublicApiSuccessSchema(
  irFormSchema.array()
);

export const createIrFormsExportResponseSchema = getPublicApiSuccessSchema(
  z.object({
    exportId: uuidSchema,
  })
);

export const createIrFormsExportRequestSchema = z.object({
  accountExternalIds: z.string().array().optional(),
  includeColumns: z.string().array().optional(),
  useReportingProfile: z.boolean().optional(),
});
export type CreateIrFormsExportRequest = z.infer<
  typeof createIrFormsExportRequestSchema
>;

const irFormKeysSchema = z.object({
  accountId: accountIdSchema,
  revisionKey: revisionKeySchema,
});
export type IrFormKeys = z.infer<typeof irFormKeysSchema>;

export const rescindIrFormsRequestSchema = getPublicApiSuccessSchema(
  z.object({
    taxForms: irFormKeysSchema.array(),
  })
);
export type RescindIrFormsRequest = z.infer<typeof rescindIrFormsRequestSchema>;

export const rescindIrFormsResponseSchema = getPublicApiSuccessSchema(
  z.object({
    failed: irFormKeysSchema.array(),
  })
);

const baseIrFormsFiltersSchema = z.object({
  accountExternalId: z.string().optional(),
  accountExternalIds: z.string().array().optional(),
  accountAlternateExternalId: z.string().optional(),
  dateGenerated: dateFiltersSchema.optional(),
  name: z.string().optional(),
  statuses: irFormStatusSchema.array().optional(),
  types: irFormTypeSchema.array().optional(),
});
export type BaseIrFormsFilters = z.infer<typeof baseIrFormsFiltersSchema>;

export const downloadIrFormsRequestSchema = getPublicApiSuccessSchema(
  z.object({
    taxFormKeys: irFormKeysSchema.array().optional(),
    documentType: dashboardFormTypeSchema,
    documentYear: z
      .number()
      .transform((year) => fourDigitYearSchema.parse(year)),
    filters: baseIrFormsFiltersSchema.optional(),
  })
);
export type DownloadIrFormsRequest = z.infer<
  typeof downloadIrFormsRequestSchema
>;

export const searchIrFormsParamsSchema = z.object({
  filters: baseIrFormsFiltersSchema.extend({
    documentDate: fourDigitYearSchema,
    documentType: dashboardFormTypeSchema,
  }),
  page: pageSchema.optional(),
  sort: getFormsSortSchema.optional(),
  useReportingProfile: z.boolean().optional(),
});
export type SearchIrFormsParams = z.infer<typeof searchIrFormsParamsSchema>;

const generateTaxFormsSchema = z.object({
  accountIds: z.string().array().optional(),
  accountValidationsEligibilityStatus: accountValidationsEligibilityStatusSchema
    .array()
    .optional(),
  documentDate: fourDigitYearSchema,
  documentType: dashboardFormTypeSchema,
  externalId: z.string().optional(),
  externalIds: z.string().array().optional(),
  formStatus: dashboardEligibilityFormStatusSchema.array().optional(),
  name: z.string().optional(),
  should1099bIncludeCostBasis: z.boolean().optional(),
  shouldCompareSnapshots: z.boolean().optional(),
  status: eligibilityStatusSchema.array().optional(),
});

export const generateTaxFormsRequestSchema = getPublicApiSuccessSchema(
  generateTaxFormsSchema
);

export type GenerateTaxFormsRequest = z.infer<
  typeof generateTaxFormsRequestSchema
>;

export const createTaxFormsFilingRequestSchema = getPublicApiSuccessSchema(
  z.object({
    documentYear: fourDigitYearSchema,
    documentType: dashboardFormTypeSchema,
  })
);

export type CreateTaxFormsFilingRequest = z.infer<
  typeof createTaxFormsFilingRequestSchema
>;
