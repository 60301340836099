import { DashboardPrimaryTemplate } from "@taxbit-dashboard/commons";

import Reports from "./Reports";
import { ReportsDataContextProvider } from "./useReportsData";

const ReportsPage: React.FC = () => {
  return (
    <DashboardPrimaryTemplate title="Reports">
      <ReportsDataContextProvider>
        <Reports />
      </ReportsDataContextProvider>
    </DashboardPrimaryTemplate>
  );
};

export default ReportsPage;
