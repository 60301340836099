/**
 * Standardized helpers to build query paths used in the TaxBit Rest SDK. This
 * allows for easy synchronization between the real SDK endpoints and mock service
 * worker handlers intended to provide mocks for them.
 */
const pathBuilders = {
  buildLegacyAccountsPath: () => "accounts" as const,
  buildAccountsPath: () => "dashboard/accounts" as const,
  buildAccountsExportPath: (exportId: string = "accounts") =>
    `exports/${exportId}` as const,
  buildAccountPath: (accountId: string = ":accountId") =>
    `accounts/${accountId}` as const,
  buildAccountV2Path: (accountId: string = ":accountId") =>
    `accounts/v2/${accountId}` as const,
  buildAccountReportingProfilesPath: (accountId: string = ":accountId") =>
    `accounts/${accountId}/reporting-profiles` as const,
  buildAccountV2ByExternalIdPath: (externalId: string = ":externalId") =>
    `/accounts/v2/external-id/${externalId}` as const,
  buildSearchHydratedAccountsPath: () =>
    "dashboard/hydrated-accounts/search" as const,
  buildExportHydratedAccountsPath: () =>
    "dashboard/hydrated-accounts/exports" as const,
  buildHydratedAccountsFilterOptionsPath: () =>
    "dashboard/hydrated-accounts/filter-options" as const,
  buildAccountOwnerCuringsPath: () => "account-owner-curings" as const,
  buildAccountOwnerCuringArchivesPath: () =>
    "account-owner-curing-archives" as const,
  buildAccountOwnerDetailsPath: (accountOwnerId: string = ":accountOwnerId") =>
    `account-owners/${accountOwnerId}/details` as const,
  buildAccountTinPath: (accountId: string = ":accountId") =>
    `accounts/${accountId}/tin` as const,
  buildCurrentCompanyUserNamePath: () => "current-company-user/name" as const,
  buildCurrentCompanyUserPasswordChangePath: () =>
    "current-company-user/password-change" as const,
  buildCurrentCompanyUserPath: () => "current-company-user" as const,
  buildCurrentCompanyUserDownloadsPath: () => "dashboard/downloads" as const,
  buildCurrentCompanyUserOrganizationsPath: () =>
    "current-company-user-organizations" as const,
  buildCompanyUsersPath: (companyId: string = ":companyId") =>
    `companies/${companyId}/company-users` as const,
  buildMfaResetForCompanyUserPath: (companyUserId: string = ":companyUserId") =>
    `company-users/${companyUserId}/mfa/reset` as const,
  buildOrganizationMembersPath: (organizationId: string) =>
    `organizations/${organizationId}/company-users` as const,
  buildOrganizationMemberRolesPath: (
    organizationId: string,
    memberId: string
  ) =>
    `organizations/${organizationId}/company-users/${memberId}/roles` as const,
  buildOrganizationDevSettingsPath: (organizationId: string) =>
    `organizations/${organizationId}/dev-settings` as const,
  buildInvitationsPath: (organizationId: string) =>
    `organizations/${organizationId}/invitations` as const,
  buildTaxReportsPath: () => "tax/reports/all" as const,
  buildTaxReportsUrlPath: (reportType: string = ":reportType") =>
    `tax/reports/all/${reportType}` as const,
  buildTaxReportStatusPath: () => "tax/reports/statuses/all" as const,
  buildGenerateTaxReportPath: () => "tax/reports" as const,
  buildTaxDocumentationPath: (userId: string = ":userId") =>
    `users/${userId}/tax-documentation` as const,
  buildKycTaxDocumentationPath: (userId: string = ":userId") =>
    `kyc/users/${userId}/tax-documentation` as const,
  buildTaxDocumentationStatusPath: (userId: string = ":userId") =>
    `users/${userId}/tax-documentation/status` as const,
  buildKycTaxDocumentationSummaryPath: (userId: string = ":userId") =>
    `kyc/users/${userId}/tax-documentation/summary` as const,
  buildKycTaxDocumentationStatusPath: (userId: string = ":userId") =>
    `kyc/users/${userId}/tax-documentation-status` as const,
  buildKycDocumentPath: (
    userId: string = ":userId",
    documentId: string = ":documentId"
  ) => `kyc/users/${userId}/tax-documentation/document/${documentId}` as const,
  buildKycPostDocumentPath: (userId: string = ":userId") =>
    `kyc/users/${userId}/tax-documentation/document` as const,
  buildTaxFormsPath: (accountId: string = ":accountId") =>
    `accounts/${accountId}/released-documents` as const,
  buildTaxFormsUrlPath: (
    accountId: string = ":accountId",
    documentId: string = ":documentId"
  ) => `accounts/${accountId}/released-documents/${documentId}` as const,
  buildInventorySummaryPath: (accountId: string = ":accountId") =>
    `accounts/${accountId}/inventory/summary` as const,
  buildTransactionsPath: (accountId: string = ":accountId") =>
    `accounts/${accountId}/transactions` as const,
  buildTransactionPath: (
    accountId: string = ":accountId",
    transactionId: string = ":transactionId"
  ) => `accounts/${accountId}/transactions/${transactionId}` as const,
  buildGainsPath: (accountId: string = ":accountId") =>
    `accounts/${accountId}/gains` as const,
  buildGainsSummaryPath: (accountId: string = ":accountId") =>
    `accounts/${accountId}/gains/summary` as const,
  buildRolesPath: () => `roles` as const,
  buildTransfersOutPath: () => "dashboard/cbi/transfers/out" as const,
  buildTransferOutPath: (
    transactionId: string = ":transactionId",
    accountId: string = ":accountId"
  ) =>
    `dashboard/cbi/transfers/out/${transactionId}/accounts/${accountId}` as const,
  buildTransfersInPath: () => "dashboard/cbi/transfers/in" as const,
  buildTransferInPath: (
    transactionId: string = ":transactionId",
    accountId: string = ":accountId"
  ) =>
    `dashboard/cbi/transfers/in/${transactionId}/accounts/${accountId}` as const,
  buildTransferInLotsPath: (
    externalTransactionId: string = ":externalTransactionId"
  ) => `transfer-lots/transactions/${externalTransactionId}` as const,
  buildTransferOutLotsPath: (
    transactionId: string = ":transactionId",
    accountId: string = ":accountId"
  ) =>
    `dashboard/cbi/transfers/out/${transactionId}/accounts/${accountId}/lots` as const,
  buildAssetPath: (assetId: string = ":assetId") =>
    `assets/${assetId}` as const,
  buildTransfersOutMetadataPath: () =>
    "dashboard/insights/cbi/transfers/out" as const,
  buildTransfersInMetadataPath: () =>
    "dashboard/insights/cbi/transfers/in" as const,
  buildFormsMetadataPath: () => "dashboard/forms/metadata" as const,
  buildFilesPath: () => "dashboard/files" as const,
  buildFilePath: (fileId: string = ":fileId") =>
    `dashboard/files/${fileId}` as const,
  buildFileConfirmIngestionPath: (fileId: string = ":fileId") =>
    `dashboard/files/${fileId}/confirm-ingestion` as const,
  buildFileErrorReportPath: (fileId: string = ":fileId") =>
    `dashboard/files/${fileId}/error-report` as const,
  buildFilesMultipartUploadsPath: () =>
    "dashboard/files/multipart-uploads" as const,
  buildFilesMultipartUploadPath: (uploadId: string = ":uploadId") =>
    `dashboard/files/multipart-uploads/${uploadId}` as const,
  buildFilesMultipartUploadEndPath: (uploadId: string = ":uploadId") =>
    `dashboard/files/multipart-uploads/${uploadId}/end` as const,
  buildEligibilityCountsPath: () => "dashboard/eligibility/aggregates" as const,
  buildEligibilityFindAccountsDataPath: () =>
    "dashboard/eligibility/find-accounts" as const,
  buildEligibilityAlertsPath: () => "dashboard/eligibility/alerts" as const,
  buildEligibilityExportsPath: () => "dashboard/eligibility/exports" as const,
  buildEligibilityExportPath: (exportId: string = ":exportId") =>
    `dashboard/eligibility/exports/${exportId}` as const,
  buildEligibilityBatchOverridesPath: () =>
    `dashboard/eligibility/overrides/batch` as const,
  buildEligibilitySyncTimestampsPath: () =>
    "dashboard/eligibility/sync-timestamps" as const,
  buildEligibilityQaPackagePath: () =>
    "dashboard/eligibility/qa-packages" as const,
  buildEligibilityRecalcPath: () =>
    "dashboard/eligibility/form-eligibility-execution" as const,
  buildFormItemsPath: (accountId: string = ":accountId") =>
    `accounts/${accountId}/form-items` as const,
  buildPayersPath: () => "payers" as const,
  buildPayerPath: (payerId: string = ":payerId") =>
    `payers/${payerId}` as const,
  buildIrFormsAggregatesPath: () => "dashboard/tax-forms/aggregations" as const,
  buildSearchIrFormsPath: () => "dashboard/tax-forms/search" as const,
  buildIrFormsBatchPath: () => `dashboard/tax-forms/batch` as const,
  buildIrFormsExportsPath: () => `dashboard/tax-forms/exports` as const,
  buildIrFormsExportPath: (exportId: string = ":exportId") =>
    `dashboard/tax-forms/exports/${exportId}` as const,
  buildIrFormsRescindPath: () => "dashboard/tax-forms/rescind" as const,
  buildIrFormsDownloadsPath: () => "dashboard/tax-forms/downloads" as const,
  buildIrFormsFilingPath: () => `dashboard/tax-forms/approve-filing` as const,
  buildNotificationsPath: () => "notifications" as const,
  buildNotificationPath: (notificationId: string = ":notificationId") =>
    `notifications/${notificationId}` as const,
  buildNotificationsReadPath: () => "notifications/read" as const,
  buildOverviewTransactionsPath: () =>
    "dashboard/overview/transactions" as const,
  buildOverviewFormItemsPath: () => "dashboard/overview/formitems" as const,
  buildFormItemsYearsPath: () => "dashboard/form-items/available" as const,
  buildOverviewEligibilityPath: () => "dashboard/overview/eligibility" as const,
} as const;

export default pathBuilders;
