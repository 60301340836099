import { useDashboardFeatureFlags } from "@taxbit-dashboard/commons";
import { TopNavigationActionButtonGroup } from "@taxbit-private/cosmic";

import NotificationsCenterContents from "./NotificationsCenterContents";
import TopNavigationPopover from "../../app/navigation/top-navigation/shared/TopNavigationPopover";
import ActiveJobsContents from "../active-jobs/ActiveJobsContents";
import { useActiveJobsDataContext } from "../active-jobs/useActiveJobsData";
import useNotificationsContext, {
  NotificationsContext,
} from "../useNotificationsContext";

export enum NotificationsCenterTrackingId {
  ToggleButton = "notifications-center-toggle-button",
  ActiveJobsToggleButton = "active-jobs-toggle-button",
}

const NotificationsCenter: React.FC = () => {
  const { hasActiveJobsAccess } = useDashboardFeatureFlags();

  const { shouldShowUnreadNotifications, isLoading, targetButtonRef } =
    useNotificationsContext(NotificationsContext.Center);
  const { isLoading: isActiveJobsLoading, activeJobs } =
    useActiveJobsDataContext();

  const shouldShowActiveJobsDot = isActiveJobsLoading
    ? false
    : activeJobs.length > 0;

  return (
    <TopNavigationActionButtonGroup>
      {hasActiveJobsAccess && (
        <TopNavigationPopover
          targetButtonProps={{
            iconName: "check-circle-dash",
            trackingId: NotificationsCenterTrackingId.ActiveJobsToggleButton,
            label: "Active Jobs",
            isDisabled: isActiveJobsLoading,
          }}
          contents={<ActiveJobsContents />}
          hasDot={shouldShowActiveJobsDot}
        />
      )}
      <TopNavigationPopover
        targetButtonProps={{
          iconName: "bell",
          trackingId: NotificationsCenterTrackingId.ToggleButton,
          label: "Notifications",
          isDisabled: isLoading,
        }}
        targetButtonRef={targetButtonRef}
        contents={<NotificationsCenterContents />}
        hasDot={shouldShowUnreadNotifications}
      />
    </TopNavigationActionButtonGroup>
  );
};

export default NotificationsCenter;
