import { createSingleInstanceHookContext } from "@taxbit-dashboard/commons";
import { TaxReportStatus } from "@taxbit-dashboard/rest";
import { useCallback, useMemo } from "react";

import useGetAllReports from "./reports-table/useGetAllReports";
import { TaxReport } from "./taxReportTypes";
import { useGetTaxReportStatuses } from "../../api/taxReportsApi";

const useReportsData = () => {
  const {
    data: taxReportStatuses,
    isError: isErrorStatuses,
    isLoading: isLoadingStatuses,
  } = useGetTaxReportStatuses();
  const {
    reports,
    isError: isErrorReports,
    isLoading: isLoadingReports,
  } = useGetAllReports(taxReportStatuses);

  const hasNoReports = useMemo(() => !reports?.length, [reports]);

  const getReportStatus = useCallback(
    (report: TaxReport) => {
      return taxReportStatuses?.find(
        (status) => status.reportName === report.key
      );
    },
    [taxReportStatuses]
  );

  const isReportGenerationInProgress = useCallback(
    (report: TaxReport) => {
      const status = getReportStatus(report)?.status;
      return status === TaxReportStatus.InProgress;
    },
    [getReportStatus]
  );

  const isLoading = useMemo(
    () => isLoadingStatuses || isLoadingReports,
    [isLoadingStatuses, isLoadingReports]
  );

  const isError = useMemo(
    () => isErrorStatuses || isErrorReports,
    [isErrorStatuses, isErrorReports]
  );

  return {
    taxReportStatuses,
    reports,
    hasNoReports,
    isReportGenerationInProgress,
    getReportStatus,
    isLoading,
    isError,
  };
};

export const {
  Provider: ReportsDataContextProvider,
  useContextHook: useReportsDataContext,
} = createSingleInstanceHookContext(useReportsData, "useReportsDataContext");
