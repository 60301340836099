import { AccountOwnerIssue, KycTaxDocumentType } from "@taxbit-dashboard/rest";
import { Modal, Flex, Alert } from "@taxbit-private/cosmic";

import useGetIssueDetailsModalInfo, {
  IssueDetailsModalTrackingId,
} from "./useGetIssueDetailsModalInfo";
import { isTaxDocumentationWarning } from "../../../../utils/isTaxDocumentationWarning";

type IssueDetailsModalProps = {
  isModalOpen: boolean;
  onModalClose: () => void;
  issue: AccountOwnerIssue;
  taxDocumentType: KycTaxDocumentType;
  targetString?: string;
};

export const curingErrorText =
  "We were unable to resolve this issue. Please try again, or contact TaxBit support if the issue persists.";

export const curingArchiveErrorText =
  "We were unable to re-open this issue. Please try again, or contact TaxBit support if the issue persists.";

export const IssueDetailsModal: React.FC<IssueDetailsModalProps> = ({
  onModalClose,
  isModalOpen,
  issue,
  taxDocumentType,
  targetString,
}) => {
  const {
    primaryBtnProps,
    secondaryBtnProps,
    content,
    isCuringError,
    isCuringArchiveError,
    resetCreateCuringState,
    resetCreateCuringArchiveState,
  } = useGetIssueDetailsModalInfo(
    taxDocumentType,
    issue,
    onModalClose,
    targetString
  );

  const onCloseModal = () => {
    resetCreateCuringState();
    resetCreateCuringArchiveState();
    onModalClose();
  };

  return (
    <Modal
      title={`${isTaxDocumentationWarning(issue.issueType) ? "Warning" : "Issue"} Details`}
      isOpen={isModalOpen}
      onClose={onCloseModal}
      primaryButtonProps={primaryBtnProps}
      {...secondaryBtnProps}
      closeButtonTrackingId={IssueDetailsModalTrackingId.CloseBtn}
    >
      <Flex direction="column" gap="m">
        {isCuringError && <Alert variant="danger">{curingErrorText}</Alert>}
        {isCuringArchiveError && (
          <Alert variant="danger">{curingArchiveErrorText}</Alert>
        )}
        {content}
      </Flex>
    </Modal>
  );
};
