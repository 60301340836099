import { Button, Flex } from "@taxbit-private/cosmic";
import { RhfTextInput } from "@taxbit-private/cosmic-react-hook-form";
import { FormProvider } from "react-hook-form";
import styled from "styled-components";

import AccountNotFoundModal from "./AccountNotFoundModal";
import useAccountLookupForm, {
  AccountLookupFormFields,
  AccountLookupTrackingId,
} from "./useAccountLookupForm";

const AccountLookup = () => {
  const {
    onSubmit,
    formMethods,
    isAccountNotFoundModalOpen,
    setIsAccountNotFoundModalOpen,
    isLoading,
  } = useAccountLookupForm();

  return (
    <>
      <AccountNotFoundModal
        isOpen={isAccountNotFoundModalOpen}
        onClose={() => {
          setIsAccountNotFoundModalOpen(false);
          formMethods.reset({ externalId: "" });
        }}
      />
      <FormProvider {...formMethods}>
        <Flex as="form" gap="s" onSubmit={onSubmit}>
          <FullWidthInput
            name="externalId"
            label="Navigate to account by ID"
            isLabelHidden={true}
            trackingId={AccountLookupTrackingId.Input}
            placeholder="Enter account ID"
          />
          <Button
            type="submit"
            label="Go to account"
            trackingId={AccountLookupTrackingId.SubmitButton}
            isLoading={isLoading}
            loadingText="Searching..."
          />
        </Flex>
      </FormProvider>
    </>
  );
};

const FullWidthInput = styled(RhfTextInput<AccountLookupFormFields>)`
  flex: 1;
`;

export default AccountLookup;
