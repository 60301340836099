import { useDashboardStore } from "@taxbit-dashboard/commons";
import { CompanyUser } from "@taxbit-dashboard/rest";
import { Modal, ModalProps, Body, Flex, Avatar } from "@taxbit-private/cosmic";

import { useResetMfaForCompanyUser } from "../../../../api/companyUsersApi";
import { TOAST_TIMEOUT } from "../../../../utils/toastTimeout";

export enum ResetMfaModalTrackingId {
  CloseButton = "mfa-reset-close-button",
  CancelButton = "mfa-reset-cancel-button",
  SubmitButton = "mfa-reset-submit-button",
  SuccessToast = "mfa-reset-success-toast",
  ErrorToast = "mfa-reset-error-toast",
}

type Props = Pick<ModalProps, "isOpen" | "onClose"> & {
  user: CompanyUser;
};

const ResetMfaForCompanyUserModal: React.FC<Props> = ({
  onClose,
  isOpen,
  user,
}) => {
  const { mutate: resetMfa, isLoading: isLoadingResetMfa } =
    useResetMfaForCompanyUser();
  const addToast = useDashboardStore((store) => store.addToast);

  const handleSubmit = () => {
    resetMfa(
      { companyUserId: user.id },
      {
        onSuccess: () => {
          addToast({
            message: `MFA has been reset for ${user.name}. They will be prompted to configure MFA on their next login.`,
            trackingId: ResetMfaModalTrackingId.SuccessToast,
            timeoutMs: TOAST_TIMEOUT,
          });
        },
        onError: () => {
          addToast({
            message:
              "An error occurred and the user's MFA was not reset. Please try again.",
            trackingId: ResetMfaModalTrackingId.ErrorToast,
            variant: "danger",
            timeoutMs: TOAST_TIMEOUT,
          });
        },
        onSettled: () => onClose(),
      }
    );
  };

  return (
    <Modal
      title="Confirm Multi-Factor Authentication Reset"
      isOpen={isOpen}
      onClose={onClose}
      closeButtonTrackingId={ResetMfaModalTrackingId.CloseButton}
      secondaryButtonProps={{
        label: "Cancel",
        trackingId: ResetMfaModalTrackingId.CancelButton,
        onClick: onClose,
      }}
      primaryButtonProps={{
        label: "Reset",
        trackingId: ResetMfaModalTrackingId.SubmitButton,
        isLoading: isLoadingResetMfa,
        loadingText: "Resetting...",
        variant: "button-danger",
        onClick: handleSubmit,
      }}
    >
      <Flex gap="m" alignItems="center">
        <Avatar iconName="alert-triangle" variant="danger" size="large" />
        <Body>Are you sure you want to reset MFA for {user.name}?</Body>
      </Flex>
    </Modal>
  );
};

export default ResetMfaForCompanyUserModal;
