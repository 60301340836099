import { pageLimitPaginationParamsSchema } from "@taxbit-dashboard/commons";
import { NotificationCategory } from "@taxbit-dashboard/rest";
import { AvatarProps, IconName } from "@taxbit-private/cosmic";
import { z } from "zod";

export const notificationCategoryToUiConfigMap: Record<
  NotificationCategory,
  {
    label: string;
    avatarVariant: AvatarProps["variant"];
    icon: IconName;
  }
> = {
  [NotificationCategory.AccountDeletionComplete]: {
    avatarVariant: "success",
    icon: "trash",
    label: "Deletion complete",
  },
  [NotificationCategory.AccountDeletionValidationComplete]: {
    avatarVariant: "success",
    icon: "check-circle",
    label: "Deletion validation complete",
  },
  [NotificationCategory.AccountIngestionComplete]: {
    avatarVariant: "success",
    icon: "upload",
    label: "Ingestion complete",
  },
  [NotificationCategory.AccountIngestionValidationComplete]: {
    avatarVariant: "success",
    icon: "check-circle",
    label: "Ingestion validation complete",
  },
  [NotificationCategory.AccountsExportComplete]: {
    avatarVariant: "success",
    icon: "file",
    label: "Export available for download",
  },
  [NotificationCategory.DeletionFailed]: {
    avatarVariant: "danger",
    icon: "alert-circle",
    label: "Deletion failed",
  },
  [NotificationCategory.DeletionValidationFailed]: {
    avatarVariant: "danger",
    icon: "alert-circle",
    label: "Deletion validation failed",
  },
  [NotificationCategory.EligibilityDataSyncComplete]: {
    avatarVariant: "success",
    icon: "database",
    label: "Eligibility processed data sync complete",
  },
  [NotificationCategory.EligibilityDataSyncFailed]: {
    avatarVariant: "danger",
    icon: "alert-circle",
    label: "Eligibility processed data sync failed",
  },
  [NotificationCategory.EligibilityExportComplete]: {
    avatarVariant: "success",
    icon: "file",
    label: "Export available for download",
  },
  [NotificationCategory.EligibilityProcessingComplete]: {
    avatarVariant: "success",
    icon: "check-circle",
    label: "Eligibility data processing complete",
  },
  [NotificationCategory.EligibilityProcessingFailed]: {
    avatarVariant: "danger",
    icon: "alert-circle",
    label: "Eligibility data processing failed",
  },
  [NotificationCategory.ExportFailed]: {
    avatarVariant: "danger",
    icon: "alert-circle",
    label: "Export generation failed",
  },
  [NotificationCategory.FormDeletionComplete]: {
    avatarVariant: "success",
    icon: "trash",
    label: "Deletion complete",
  },
  [NotificationCategory.FormDeletionValidationComplete]: {
    avatarVariant: "success",
    icon: "check-circle",
    label: "Deletion validation complete",
  },
  [NotificationCategory.FormDownloadComplete]: {
    avatarVariant: "success",
    icon: "download",
    label: "Forms available for download",
  },
  [NotificationCategory.FormDownloadFailed]: {
    avatarVariant: "danger",
    icon: "alert-circle",
    label: "Form download failed",
  },
  [NotificationCategory.FormDownloadStarted]: {
    avatarVariant: "primary",
    icon: "download",
    label: "Form Downloads",
  },
  [NotificationCategory.FormFilingComplete]: {
    avatarVariant: "success",
    icon: "file-text",
    label: "Form filing process complete",
  },
  [NotificationCategory.FormFilingFailed]: {
    avatarVariant: "danger",
    icon: "alert-circle",
    label: "Form filing process failed",
  },
  [NotificationCategory.FormFilingStarted]: {
    avatarVariant: "primary",
    icon: "alert-circle",
    label: "Form Filing",
  },
  [NotificationCategory.FormGenerationComplete]: {
    avatarVariant: "success",
    icon: "check-circle",
    label: "Form generation report available for download",
  },
  [NotificationCategory.FormGenerationFailed]: {
    avatarVariant: "danger",
    icon: "alert-circle",
    label: "Form generation failed",
  },
  [NotificationCategory.FormGenerationStarted]: {
    avatarVariant: "primary",
    icon: "check-circle",
    label: "Form Generation",
  },
  [NotificationCategory.FormIngestionComplete]: {
    avatarVariant: "success",
    icon: "upload",
    label: "Ingestion complete",
  },
  [NotificationCategory.FormIngestionValidationComplete]: {
    avatarVariant: "success",
    icon: "check-circle",
    label: "Ingestion validation complete",
  },
  [NotificationCategory.FormsExportComplete]: {
    avatarVariant: "success",
    icon: "file",
    label: "Export available for download",
  },
  [NotificationCategory.IngestionFailed]: {
    avatarVariant: "danger",
    icon: "alert-circle",
    label: "Ingestion failed",
  },
  [NotificationCategory.IngestionValidationFailed]: {
    avatarVariant: "danger",
    icon: "alert-circle",
    label: "Ingestion validation failed",
  },
  [NotificationCategory.InvalidFile]: {
    avatarVariant: "danger",
    icon: "alert-circle",
    label: "Ingestion validation complete",
  },
  [NotificationCategory.QaPackageGenerationComplete]: {
    avatarVariant: "success",
    icon: "package",
    label: "QA package available for download",
  },
  [NotificationCategory.QaPackageGenerationFailed]: {
    avatarVariant: "danger",
    icon: "alert-circle",
    label: "QA package generation failed",
  },
  [NotificationCategory.QaPackageGenerationStarted]: {
    avatarVariant: "primary",
    icon: "package",
    label: "QA Package Download",
  },
  [NotificationCategory.ReportFailed]: {
    avatarVariant: "danger",
    icon: "alert-circle",
    label: "Report generation failed",
  },
  [NotificationCategory.ReportGenerationComplete]: {
    avatarVariant: "success",
    icon: "file",
    label: "Report available for download",
  },
  [NotificationCategory.TransactionDeletionComplete]: {
    avatarVariant: "success",
    icon: "trash",
    label: "Deletion complete",
  },
  [NotificationCategory.TransactionDeletionValidationComplete]: {
    avatarVariant: "success",
    icon: "check-circle",
    label: "Deletion validation complete",
  },
  [NotificationCategory.TransactionIngestionComplete]: {
    avatarVariant: "success",
    icon: "upload",
    label: "Ingestion complete",
  },
  [NotificationCategory.TransactionIngestionValidationComplete]: {
    avatarVariant: "success",
    icon: "check-circle",
    label: "Ingestion validation complete",
  },
};

export enum NotificationsTab {
  All = "all",
  Unread = "unread",
  Read = "read",
}

export const notificationsTabTitleMap = {
  [NotificationsTab.All]: "All",
  [NotificationsTab.Read]: "Read",
  [NotificationsTab.Unread]: "Unread",
};

export const notificationsPageParamsSchema =
  pageLimitPaginationParamsSchema.extend({
    tab: z.nativeEnum(NotificationsTab).catch(NotificationsTab.All),
  });

export type NotificationsPageParams = z.infer<
  typeof notificationsPageParamsSchema
>;
