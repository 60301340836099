import { useMutation } from "@tanstack/react-query";
import {
  createQueryMetaObject,
  useTaxBitRest,
} from "@taxbit-dashboard/commons";

import {
  EligibilityDataParams,
  getEligibilityExportRequest,
} from "./eligibleUsersApiTypes";
import getEligibilityParams from "./getEligibilityParams";
import { ExportEligibilityTableModalForm } from "../../../pages/information-reporting/eligible-users/context/exportEligibilityTableModalFormTypes";
import {
  AccountExternalIdsFilter,
  EligibilityExportFormatParams,
} from "../irApiTypes";

export const useGenerateEligibilityExport = () => {
  const restSdk = useTaxBitRest();

  return useMutation(
    (
      params: EligibilityDataParams &
        ExportEligibilityTableModalForm &
        AccountExternalIdsFilter &
        EligibilityExportFormatParams
    ) => {
      const apiParams = getEligibilityParams(params);
      const {
        "page[offset]": offset,
        "page[limit]": limit,
        ...filterParams
      } = apiParams;
      const requestData = getEligibilityExportRequest(params);

      return restSdk.eligibility.exports.post(filterParams, requestData);
    },
    {
      ...createQueryMetaObject(restSdk.eligibility.exports.buildPath()),
    }
  );
};
