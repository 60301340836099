import { createSingleInstanceHookContext } from "@taxbit-dashboard/commons";
import { useMemo } from "react";

import {
  useGetProgressNotifications,
  useNotificationsWebSocket,
} from "../../../api/notifications/notificationsApi";

const useActiveJobsData = () => {
  const {
    isLoading: isLoadingActiveJobs,
    activeJobs,
    isError: isErrorLoadingActiveJobs,
  } = useGetProgressNotifications();

  const { isConnectingWebSocket, isErrorWebSocket } =
    useNotificationsWebSocket();

  const isError = useMemo(
    () => isErrorLoadingActiveJobs || isErrorWebSocket,
    [isErrorLoadingActiveJobs, isErrorWebSocket]
  );

  const isLoading = useMemo(
    () => isLoadingActiveJobs || isConnectingWebSocket,
    [isConnectingWebSocket, isLoadingActiveJobs]
  );

  return {
    isLoading,
    isError,
    activeJobs,
  };
};

export const {
  Provider: ActiveJobsDataContextProvider,
  useContextHook: useActiveJobsDataContext,
} = createSingleInstanceHookContext(
  useActiveJobsData,
  "useActiveJobsDataContext"
);
