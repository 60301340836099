import { formatTaxId, isDefined } from "@taxbit-dashboard/commons";
import {
  KycTaxDocumentation,
  KycTaxDocumentationStatus,
} from "@taxbit-dashboard/rest";
import { ActionSnippet, DenseRowGroup, Modal } from "@taxbit-private/cosmic";
import {
  COSMIC_VALUE_PLACEHOLDER,
  useCosmicLocalizationContext,
} from "@taxbit-private/cosmic-localization";

import {
  taxClassificationMap,
  treatyClaimLimitationOnBenefitsMap,
  treatyClaimTypeOfIncomeMap,
} from "../../../../api/kyc-tax-documentation/kycTaxDocumentationApiTypes";
import { getIsoCountryName } from "../../../../utils/getIsoCountryName";
import { isWForm } from "../../../../utils/isWForm";
import maskTaxId from "../../../../utils/maskTaxId";
import BadgeWithoutLabelTransformation from "../../../BadgeWithoutLabelTransformation";
import AccountAddress from "../AccountAddress";
import InvalidCountry from "../InvalidCountry";
import TinBadge from "../TinBadge";
import VatBadge from "../VatBadge";

type TaxDocumentationDetailsModalProps = {
  shouldUnmaskValue: boolean;
  isOpen: boolean;
  onClose: () => void;
  data: KycTaxDocumentation;
  taxDocStatus?: KycTaxDocumentationStatus;
};

export enum TaxDocumentationDetailsModalTrackingId {
  CloseBtn = "tax-documentation-details-modal-close-btn",
  PrimaryBtn = "tax-documentation-details-modal-primary-btn",
  DocumentId = "tax-documentation-details-modal-document-id",
  Tin = "tax-documentation-details-modal-tin",
  Ftin = "tax-documentation-details-modal-ftin",
  Vatin = "tax-documentation-details-modal-vatin",
  FinancialAccountId = "tax-documentation-details-modal-financial-account-id",
  BusinessRegistrationNumber = "tax-documentation-details-modal-business-registration-number",
  ResidencyTin = "tax-documentation-details-modal-residency-tin",
}

const TaxDocumentationDetailsModal = ({
  isOpen,
  onClose,
  data,
  shouldUnmaskValue,
  taxDocStatus,
}: TaxDocumentationDetailsModalProps) => {
  const { formatDateTime, formatBoolean } = useCosmicLocalizationContext();
  const tinType = data.documentType === "W-9" ? data.tinType : undefined;
  const isW9 = data.documentType === "W-9";
  const isW8 =
    data.documentType === "W-8BEN" || data.documentType === "W-8BEN-E";
  const isDps = data.documentType === "DPS";

  const w8Country =
    data.documentType === "W-8BEN" || data.documentType === "W-8BEN-E"
      ? getIsoCountryName(data.country)
      : undefined;

  const vatCountry =
    data.documentType === "DPS"
      ? getIsoCountryName(data.vatinCountry)
      : undefined;

  const birthCountry =
    data.documentType === "DPS"
      ? getIsoCountryName(data.countryOfBirth)
      : undefined;

  const businessRegistrationCountry =
    data.documentType === "DPS"
      ? getIsoCountryName(data.businessRegistrationCountry)
      : undefined;

  const treatyCountry =
    data.documentType === "W-8BEN" || data.documentType === "W-8BEN-E"
      ? getIsoCountryName(data.treatyClaimCountry)
      : undefined;

  const generalDataFields = {
    key: "general-data",
    rows: [
      { label: "Name", content: data.name, key: "name" },
      ...(isW9
        ? [
            {
              label: "Doing Business As",
              content: data.dbaName,
              key: "dba-name",
            },
          ]
        : []),
      ...(isW8 && data.referenceNumbers
        ? [
            {
              label: "Reference Numbers",
              content: data.referenceNumbers,
              key: "reference-numbers",
            },
          ]
        : []),
      ...(isWForm(data)
        ? [
            {
              label: "Tax Classification",
              content: taxClassificationMap[data.taxClassification],
              key: "tax-classification",
            },
          ]
        : []),
      ...(isW9 && data.otherTaxClassification
        ? [
            {
              label: "Other Tax Classification",
              content: data.otherTaxClassification,
              key: "other-tax-classification",
            },
          ]
        : []),
      ...(isDps
        ? [
            {
              label: "Classification",
              content: data.isIndividual
                ? taxClassificationMap.INDIVIDUAL
                : taxClassificationMap.ENTITY,
              key: "classification",
            },
          ]
        : []),
      ...(data.documentType === "W-8BEN" || (isDps && data.isIndividual)
        ? [
            {
              label: "Date of Birth",
              content: formatDateTime({
                date: data.dateOfBirth,
              }),
              key: "date-of-birth",
            },
          ]
        : []),
      ...(isW8
        ? [
            {
              label: "Country",
              content: w8Country === "" ? <InvalidCountry /> : w8Country,
              key: "country",
            },
          ]
        : []),
      ...(isDps && data.isIndividual
        ? [
            {
              label: "Birth City",
              content: data.cityOfBirth,
              key: "birth-city",
            },
            {
              label: "Birth Country",
              content: birthCountry === "" ? <InvalidCountry /> : birthCountry,
              key: "birth-country",
            },
          ]
        : []),
      ...(isW9 || isDps
        ? [
            {
              label: "Address",
              content: data.address ? (
                <AccountAddress address={data.address} />
              ) : (
                COSMIC_VALUE_PLACEHOLDER
              ),
              key: "address",
            },
          ]
        : []),
      ...(isW8
        ? [
            {
              label: "Permanent Address",
              content: <AccountAddress address={data.permanentAddress} />,
              key: "permanent-address",
            },
            ...(JSON.stringify(data.mailingAddress) ===
            JSON.stringify(data.permanentAddress)
              ? []
              : [
                  {
                    label: "Mailing Address",
                    content: data.mailingAddress ? (
                      <AccountAddress address={data.mailingAddress} />
                    ) : (
                      COSMIC_VALUE_PLACEHOLDER
                    ),
                    key: "mailing-address",
                  },
                ]),
          ]
        : []),
      ...(isW9
        ? [
            ...(data.exemptPayeeCode
              ? [
                  {
                    label: "Exempt Payee Code",
                    content: data.exemptPayeeCode,
                    key: "exempt-payee-code",
                  },
                ]
              : []),
            ...(data.exemptFatcaCode
              ? [
                  {
                    label: "Exempt FATCA Code",
                    content: data.exemptFatcaCode,
                    key: "exempt-fatca-code",
                  },
                ]
              : []),
          ]
        : []),
      ...(isDps
        ? [
            ...(data.businessRegistrationCountry
              ? [
                  {
                    label: "Business Registration Country",
                    content:
                      businessRegistrationCountry === "" ? (
                        <InvalidCountry />
                      ) : (
                        businessRegistrationCountry
                      ),
                    key: "business-registration-country",
                  },
                ]
              : []),
            ...(data.businessRegistrationNumber
              ? [
                  {
                    label: "Business Registration Number",
                    content: (
                      <ActionSnippet
                        contents={formatTaxId(data.businessRegistrationNumber)}
                        shouldMask={shouldUnmaskValue}
                        maskContents={(value) =>
                          maskTaxId(value) ?? COSMIC_VALUE_PLACEHOLDER
                        }
                        trackingId={
                          TaxDocumentationDetailsModalTrackingId.BusinessRegistrationNumber
                        }
                      />
                    ),
                    key: TaxDocumentationDetailsModalTrackingId.BusinessRegistrationNumber,
                  },
                ]
              : []),
            ...(data.financialAccountIdentifier
              ? [
                  {
                    label: "Financial Account ID",
                    content: (
                      <ActionSnippet
                        contents={formatTaxId(data.financialAccountIdentifier)}
                        shouldMask={shouldUnmaskValue}
                        maskContents={(value) =>
                          maskTaxId(value) ?? COSMIC_VALUE_PLACEHOLDER
                        }
                        trackingId={
                          TaxDocumentationDetailsModalTrackingId.FinancialAccountId
                        }
                      />
                    ),
                    key: TaxDocumentationDetailsModalTrackingId.FinancialAccountId,
                  },
                ]
              : []),
            ...(data.financialAccountName
              ? [
                  {
                    label: "Financial Account Holder Name",
                    content: data.financialAccountName,
                    key: "financial-account-holder-name",
                  },
                ]
              : []),
          ]
        : []),
    ],
  };

  const wFormTinFields = isWForm(data)
    ? [
        {
          key: "w-form-tin",
          rows: [
            {
              label: "US TIN",
              content: (
                <ActionSnippet
                  contents={formatTaxId(data.tin, tinType)}
                  shouldMask={shouldUnmaskValue}
                  maskContents={(value) =>
                    maskTaxId(value, tinType) ?? COSMIC_VALUE_PLACEHOLDER
                  }
                  trackingId={TaxDocumentationDetailsModalTrackingId.Tin}
                />
              ),
              key: TaxDocumentationDetailsModalTrackingId.Tin,
            },
            ...(isW9
              ? [
                  {
                    label: "US TIN Type",
                    content: data.tinType,
                    key: "us-tin-type",
                  },
                  {
                    label: "US TIN Status",
                    content: (
                      <TinBadge
                        tinValidationStatus={
                          taxDocStatus?.wFormQuestionnaire?.tinStatus
                        }
                      />
                    ),
                    key: "us-tin-status",
                  },
                  {
                    label: "US TIN Verification Date",
                    content: formatDateTime({
                      date: taxDocStatus?.wFormQuestionnaire?.tinValidationDate,
                    }),
                    key: "us-tin-verification-status",
                  },
                ]
              : []),
            ...(isW8
              ? [
                  ...(taxDocStatus?.wFormQuestionnaire?.tinStatus
                    ? [
                        {
                          label: "US TIN Status",
                          content: (
                            <TinBadge
                              tinValidationStatus={
                                taxDocStatus?.wFormQuestionnaire?.tinStatus
                              }
                            />
                          ),
                          key: "us-tin-status",
                        },
                      ]
                    : []),
                  ...(taxDocStatus?.wFormQuestionnaire?.tinValidationDate
                    ? [
                        {
                          label: "US TIN Verification Date",
                          content: formatDateTime({
                            date: taxDocStatus?.wFormQuestionnaire
                              ?.tinValidationDate,
                          }),
                          key: "us-tin-verification-status",
                        },
                      ]
                    : []),
                  {
                    label: "Foreign TIN",
                    content: data.ftinNotLegallyRequired ? (
                      <BadgeWithoutLabelTransformation
                        label="Not Required"
                        variant="secondary"
                      />
                    ) : (
                      <ActionSnippet
                        contents={formatTaxId(data.ftin)}
                        shouldMask={shouldUnmaskValue}
                        maskContents={(value) =>
                          maskTaxId(value) ?? COSMIC_VALUE_PLACEHOLDER
                        }
                        trackingId={TaxDocumentationDetailsModalTrackingId.Ftin}
                      />
                    ),
                    key: TaxDocumentationDetailsModalTrackingId.Ftin,
                  },
                ]
              : []),
          ],
        },
      ]
    : [];

  const w8TreatyFields = isW8
    ? [
        ...(data.treatyClaimCountry
          ? [
              {
                label: "Treaty Country",
                content:
                  treatyCountry === "" ? <InvalidCountry /> : treatyCountry,
                key: "treay-country",
              },
            ]
          : []),
        ...(data.documentType === "W-8BEN-E" &&
        data.treatyClaimLimitationOnBenefits
          ? [
              {
                label: "Limitation On Benefits Provision",
                content: data.treatyClaimLimitationOnBenefits
                  ? treatyClaimLimitationOnBenefitsMap[
                      data.treatyClaimLimitationOnBenefits
                    ]
                  : COSMIC_VALUE_PLACEHOLDER,
                key: "limitation-on-benefits-provision",
              },
            ]
          : []),
        ...(data.treatyClaimTypeOfIncome
          ? [
              {
                label: "Income Type",
                content: data.treatyClaimTypeOfIncome
                  ? treatyClaimTypeOfIncomeMap[data.treatyClaimTypeOfIncome]
                  : COSMIC_VALUE_PLACEHOLDER,
                key: "income-type",
              },
            ]
          : []),
        ...(data.treatyClaimRateOfWithholding
          ? [
              {
                label: "Rate of Withholding",
                content: `${data.treatyClaimRateOfWithholding}%`,
                key: "rate-of-withholding",
              },
            ]
          : []),
        ...(data.treatyClaimArticleParagraph
          ? [
              {
                label: "Article & Paragraph",
                content: data.treatyClaimArticleParagraph,
                key: "article-and-paragraph",
              },
            ]
          : []),
        ...(isDefined(data.treatyClaimHasAdditionalConditions)
          ? [
              {
                label: "Additional Conditions",
                content: formatBoolean(data.treatyClaimHasAdditionalConditions),
                key: "additional-conditions",
              },
            ]
          : []),
      ]
    : [];

  const vatFields =
    data.documentType === "DPS"
      ? [
          {
            key: "vat",
            rows: [
              ...(data.vatinCountry
                ? [
                    {
                      label: "VAT Country",
                      content:
                        vatCountry === "" ? <InvalidCountry /> : vatCountry,
                      key: "vat-country",
                    },
                  ]
                : []),
              ...(data.vatinNotRequired &&
              taxDocStatus?.dpsQuestionnaire?.vatStatus === "NOT_REQUIRED"
                ? []
                : [
                    {
                      label: "VAT Number",
                      content: data.vatinNotRequired ? (
                        <BadgeWithoutLabelTransformation
                          label="Not Required"
                          variant="secondary"
                        />
                      ) : (
                        <ActionSnippet
                          contents={formatTaxId(data.vatin)}
                          shouldMask={shouldUnmaskValue}
                          maskContents={(value) =>
                            maskTaxId(value) ?? COSMIC_VALUE_PLACEHOLDER
                          }
                          trackingId={
                            TaxDocumentationDetailsModalTrackingId.Vatin
                          }
                        />
                      ),
                      key: TaxDocumentationDetailsModalTrackingId.Vatin,
                    },
                  ]),
              ...(taxDocStatus?.dpsQuestionnaire?.vatStatus &&
              taxDocStatus?.dpsQuestionnaire?.vatStatus !== "NON_EU"
                ? [
                    {
                      label: "VAT Number Status",
                      content: (
                        <VatBadge
                          vatValidationStatus={
                            taxDocStatus?.dpsQuestionnaire.vatStatus
                          }
                        />
                      ),
                      key: "us-tin-status",
                    },
                  ]
                : []),
            ],
          },
        ]
      : [];

  const firstResidenceCountry =
    isDps && data.taxResidences && data.taxResidences[0]
      ? getIsoCountryName(data.taxResidences[0].country)
      : undefined;

  const dpsResidenceFields = isDps
    ? [
        {
          key: "dps-residence",
          rows: [
            {
              label: "Tax Country 1",
              content:
                firstResidenceCountry === "" ? (
                  <InvalidCountry />
                ) : (
                  firstResidenceCountry
                ),
              key: `tax-country-${firstResidenceCountry}`,
            },
            {
              label: "TIN 1",
              content: data.taxResidences ? (
                data.taxResidences[0]?.tinNotRequired ? (
                  <BadgeWithoutLabelTransformation
                    label="Not Required"
                    variant="secondary"
                  />
                ) : (
                  <ActionSnippet
                    contents={formatTaxId(data.taxResidences[0]?.tin)}
                    shouldMask={shouldUnmaskValue}
                    maskContents={(value) =>
                      maskTaxId(value) ?? COSMIC_VALUE_PLACEHOLDER
                    }
                    trackingId={`${TaxDocumentationDetailsModalTrackingId.ResidencyTin}-${firstResidenceCountry}`}
                  />
                )
              ) : (
                COSMIC_VALUE_PLACEHOLDER
              ),
              key: `${TaxDocumentationDetailsModalTrackingId.ResidencyTin}-${firstResidenceCountry}`,
            },
            ...(data.taxResidences
              ? data.taxResidences.flatMap(
                  ({ tin, country, tinNotRequired }, index) => {
                    if (index === 0) {
                      return [];
                    }

                    const residenceCountry = getIsoCountryName(country);
                    return [
                      {
                        label: `Tax Country ${index + 1}`,
                        content:
                          residenceCountry === "" ? (
                            <InvalidCountry />
                          ) : (
                            residenceCountry
                          ),
                        key: `tax-country-${country}`,
                      },
                      {
                        label: `TIN ${index + 1}`,
                        content: tinNotRequired ? (
                          <BadgeWithoutLabelTransformation
                            label="Not Required"
                            variant="secondary"
                          />
                        ) : (
                          <ActionSnippet
                            contents={formatTaxId(tin)}
                            shouldMask={shouldUnmaskValue}
                            maskContents={(value) =>
                              maskTaxId(value) ?? COSMIC_VALUE_PLACEHOLDER
                            }
                            trackingId={`${TaxDocumentationDetailsModalTrackingId.ResidencyTin}-${country}`}
                          />
                        ),
                        key: `${TaxDocumentationDetailsModalTrackingId.ResidencyTin}-${country}`,
                      },
                    ];
                  }
                )
              : []),
          ],
        },
      ]
    : [];

  const certificationFields = isWForm(data)
    ? [
        {
          key: "certification",
          rows: [
            {
              label: "Has Certified",
              content: formatBoolean(data.hasCertified),
              key: "has-certified",
            },
            ...(isW9
              ? [
                  {
                    label: "Subject to Backup Withholding",
                    content: formatBoolean(!data.isNotSubjectBackupWithholding),
                    key: "subject-to-backup-withholding",
                  },
                ]
              : []),
            {
              label: "Signature Name",
              content: data.signature,
              key: "signature-name",
            },
            {
              label: "Signature Date",
              content: formatDateTime({
                date: data.signatureTimestamp,
              }),
              key: "signature-date",
            },
          ],
        },
      ]
    : [];

  return (
    <Modal
      title={`${data.documentType} Details`}
      isOpen={isOpen}
      onClose={onClose}
      size="wide"
      primaryButtonProps={{
        label: "Close",
        trackingId: TaxDocumentationDetailsModalTrackingId.PrimaryBtn,
        onClick: onClose,
      }}
      closeButtonTrackingId={TaxDocumentationDetailsModalTrackingId.CloseBtn}
    >
      <DenseRowGroup
        sections={[
          generalDataFields,
          ...wFormTinFields,
          ...dpsResidenceFields,
          ...(w8TreatyFields.length > 0
            ? [
                {
                  key: "treaty-fields",
                  rows: w8TreatyFields,
                },
              ]
            : []),
          ...vatFields,
          ...certificationFields,
        ]}
      />
    </Modal>
  );
};

export default TaxDocumentationDetailsModal;
