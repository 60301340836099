import {
  useGetDateRangeChipLabel,
  useGetPayers,
} from "@taxbit-dashboard/commons";
import { Button, Chip, ChipGroup } from "@taxbit-private/cosmic";
import { useMemo } from "react";

import { useAccountsUrlParams } from "../../useAccountsUrlParams";
import {
  TAX_CLASSIFICATION_LABELS,
  TIN_STATUS_LABELS,
  VAT_STATUS_LABELS,
} from "../accountsLabels";
import { ACCOUNTS_TABLE_ROW_LABELS } from "../accountsTableRow";

enum AccountsFilterChipsTrackingId {
  FilterChip = "accounts-filter-chip",
  ClearAllButton = "accounts-filter-chips-clear-all-button",
}

const trackingId = (key: string) =>
  `${key}-${AccountsFilterChipsTrackingId.FilterChip}`;

const ariaLabel = (key: string, value: string) =>
  `Remove ${key} filter for ${value}`;

const AccountsFilterChips: React.FC = () => {
  const { urlParams, setFilters, clearFilters } = useAccountsUrlParams();

  const getChipLabel = useGetDateRangeChipLabel();

  const { data: payers } = useGetPayers();

  const nameChipProps = useMemo(
    () =>
      urlParams.name
        ? {
            label: `${ACCOUNTS_TABLE_ROW_LABELS.short.name}: ${urlParams.name}`,
            trackingId: trackingId("name"),
            removeButtonProps: {
              "onClick": () =>
                setFilters({
                  name: undefined,
                }),
              "aria-label": ariaLabel("name", urlParams.name),
            },
          }
        : undefined,
    [urlParams.name, setFilters]
  );

  const emailChipProps = useMemo(
    () =>
      urlParams.email
        ? {
            label: `${ACCOUNTS_TABLE_ROW_LABELS.short.email}: ${urlParams.email}`,
            trackingId: trackingId("email"),
            removeButtonProps: {
              "onClick": () =>
                setFilters({
                  email: undefined,
                }),
              "aria-label": ariaLabel("email", urlParams.email),
            },
          }
        : undefined,
    [urlParams.email, setFilters]
  );

  const accountIdChipProps = useMemo(
    () =>
      urlParams.accountId
        ? {
            label: `${ACCOUNTS_TABLE_ROW_LABELS.short.externalId}: ${urlParams.accountId}`,
            trackingId: trackingId("accountId"),
            removeButtonProps: {
              "onClick": () =>
                setFilters({
                  accountId: undefined,
                }),
              "aria-label": ariaLabel("accountId", urlParams.accountId),
            },
          }
        : undefined,
    [urlParams.accountId, setFilters]
  );

  const alternateAccountIdChipProps = useMemo(
    () =>
      urlParams.alternateAccountId
        ? {
            label: `${ACCOUNTS_TABLE_ROW_LABELS.short.alternateExternalId}: ${urlParams.alternateAccountId}`,
            trackingId: trackingId("alternateAccountId"),
            removeButtonProps: {
              "onClick": () =>
                setFilters({
                  alternateAccountId: undefined,
                }),
              "aria-label": ariaLabel(
                "alternateAccountId",
                urlParams.alternateAccountId
              ),
            },
          }
        : undefined,
    [urlParams.alternateAccountId, setFilters]
  );

  const tinStatusChipProps = useMemo(
    () =>
      urlParams.tinStatuses &&
      urlParams.tinStatuses.map((value) => {
        return {
          label: `TIN Status: ${TIN_STATUS_LABELS[value]}`,
          trackingId: trackingId(`tinStatus-${value}`),
          removeButtonProps: {
            "onClick": () =>
              setFilters((curr) => ({
                ...curr,
                tinStatuses: curr.tinStatuses?.filter((v) => v !== value),
              })),
            "aria-label": ariaLabel("tinStatus", value),
          },
        };
      }),
    [urlParams.tinStatuses, setFilters]
  );

  const vatStatusChipProps = useMemo(
    () =>
      urlParams.vatStatuses &&
      urlParams.vatStatuses.map((value) => {
        return {
          label: `VAT Status: ${VAT_STATUS_LABELS[value]}`,
          trackingId: trackingId(`vatStatus-${value}`),
          removeButtonProps: {
            "onClick": () =>
              setFilters((curr) => ({
                ...curr,
                vatStatuses: curr.vatStatuses?.filter((v) => v !== value),
              })),
            "aria-label": ariaLabel("vatStatus", value),
          },
        };
      }),
    [urlParams.vatStatuses, setFilters]
  );

  const taxClassificationFilterChipsProps = useMemo(
    () =>
      urlParams.taxClassifications &&
      urlParams.taxClassifications.map((value) => {
        return {
          label: `Tax Class.: ${TAX_CLASSIFICATION_LABELS[value]}`,
          trackingId: trackingId(`taxClassification-${value}`),
          removeButtonProps: {
            "onClick": () =>
              setFilters((curr) => ({
                ...curr,
                taxClassifications: curr.taxClassifications?.filter(
                  (v) => v !== value
                ),
              })),
            "aria-label": ariaLabel("taxClassification", value),
          },
        };
      }),
    [urlParams.taxClassifications, setFilters]
  );

  const taxDocumentationTypeFilterChipsProps = useMemo(
    () =>
      urlParams.taxDocumentationTypes &&
      urlParams.taxDocumentationTypes.map((value) => {
        return {
          label: `Doc. Type: ${value}`,
          trackingId: trackingId(`taxDocumentationType-${value}`),
          removeButtonProps: {
            "onClick": () =>
              setFilters((curr) => ({
                ...curr,
                taxDocumentationTypes: curr.taxDocumentationTypes?.filter(
                  (v) => v !== value
                ),
              })),
            "aria-label": ariaLabel("taxDocumentationType", value),
          },
        };
      }),
    [urlParams.taxDocumentationTypes, setFilters]
  );

  const taxCountryCodeFilterChipsProps = useMemo(
    () =>
      urlParams.taxCountryCodes &&
      urlParams.taxCountryCodes.map((value) => {
        return {
          label: `Tax Country Code: ${value}`,
          trackingId: trackingId(`taxCountryCode-${value}`),
          removeButtonProps: {
            "onClick": () =>
              setFilters((curr) => ({
                ...curr,
                taxCountryCodes: curr.taxCountryCodes?.filter(
                  (v) => v !== value
                ),
              })),
            "aria-label": ariaLabel("taxCountryCode", value),
          },
        };
      }),
    [urlParams.taxCountryCodes, setFilters]
  );

  const filersChipProps = useMemo(
    () =>
      urlParams.filerIds &&
      payers &&
      urlParams.filerIds.map((value) => {
        const payer = payers.find((p) => p.payerId === value);
        return {
          label: `Filer: ${payer?.payerName}`,
          trackingId: trackingId(`filer-${value}`),
          removeButtonProps: {
            "onClick": () =>
              setFilters((curr) => ({
                ...curr,
                filerIds: curr.filerIds?.filter((v) => v !== value),
              })),
            "aria-label": ariaLabel("filer", value),
          },
        };
      }),
    [urlParams.filerIds, payers, setFilters]
  );

  const dateCreatedChipProps = useMemo(
    () =>
      urlParams.dateCreatedStart && urlParams.dateCreatedEnd
        ? {
            label: `Date Created: ${getChipLabel({ startDate: urlParams.dateCreatedStart, endDate: urlParams.dateCreatedEnd })}`,
            removeButtonProps: {
              "onClick": () =>
                setFilters({
                  dateCreatedStart: undefined,
                  dateCreatedEnd: undefined,
                }),
              "aria-label": "Remove filter for date created",
            },
            trackingId: trackingId("dateCreated"),
          }
        : undefined,
    [
      urlParams.dateCreatedStart,
      urlParams.dateCreatedEnd,
      getChipLabel,
      setFilters,
    ]
  );

  const totalFilterLength =
    [
      nameChipProps,
      emailChipProps,
      accountIdChipProps,
      alternateAccountIdChipProps,
      dateCreatedChipProps,
    ].filter(Boolean).length +
    (tinStatusChipProps?.length ?? 0) +
    (vatStatusChipProps?.length ?? 0) +
    (taxClassificationFilterChipsProps?.length ?? 0) +
    (taxDocumentationTypeFilterChipsProps?.length ?? 0) +
    (taxCountryCodeFilterChipsProps?.length ?? 0) +
    (filersChipProps?.length ?? 0);

  return totalFilterLength > 0 ? (
    <ChipGroup>
      {nameChipProps && <Chip {...nameChipProps} />}
      {emailChipProps && <Chip {...emailChipProps} />}
      {accountIdChipProps && <Chip {...accountIdChipProps} />}
      {alternateAccountIdChipProps && <Chip {...alternateAccountIdChipProps} />}
      {dateCreatedChipProps && <Chip {...dateCreatedChipProps} />}
      {tinStatusChipProps &&
        tinStatusChipProps.map((props) => (
          <Chip key={props.trackingId} {...props} />
        ))}
      {vatStatusChipProps &&
        vatStatusChipProps.map((props) => (
          <Chip key={props.trackingId} {...props} />
        ))}
      {taxClassificationFilterChipsProps &&
        taxClassificationFilterChipsProps.map((props) => (
          <Chip key={props.trackingId} {...props} />
        ))}
      {taxDocumentationTypeFilterChipsProps &&
        taxDocumentationTypeFilterChipsProps.map((props) => (
          <Chip key={props.trackingId} {...props} />
        ))}
      {taxCountryCodeFilterChipsProps &&
        taxCountryCodeFilterChipsProps.map((props) => (
          <Chip key={props.trackingId} {...props} />
        ))}
      {filersChipProps &&
        filersChipProps.map((props) => (
          <Chip key={props.trackingId} {...props} />
        ))}
      <Button
        trackingId={AccountsFilterChipsTrackingId.ClearAllButton}
        label="Clear All"
        size="small"
        variant="anchor-primary-inline"
        onClick={clearFilters}
      />
    </ChipGroup>
  ) : undefined;
};

export default AccountsFilterChips;
