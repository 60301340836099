import {
  UserPermission,
  isDefined,
  useDashboardFeatureFlags,
  useUserPermission,
} from "@taxbit-dashboard/commons";
import { ReportingProfile } from "@taxbit-dashboard/rest";

import { useAccountOwnerTaxDocumentContext } from "../../../../hooks/useGetAccountOwnerTaxDocumentData";
import { getMostRecentFairMarketValue } from "../../../../utils/getMostRecentFairMarketValue";

export type ReportingProfileRowData = ReportingProfile & {
  mostRecentFairMarketValue?: string;
  payerName?: string;
};

export const useGetReportingProfile = () => {
  const shouldUnmaskValue = useUserPermission(UserPermission.ReadTins);
  const { hasGlobalAccountsPageView, hasUsAccountsPageView } =
    useDashboardFeatureFlags();

  const {
    reportingProfiles,
    isReportingProfilesError,
    isReportingProfilesLoading,
    isReportingProfilesNotFound,
    payers,
  } = useAccountOwnerTaxDocumentContext();

  const globalProfile = hasGlobalAccountsPageView
    ? reportingProfiles?.reportingProfiles.find(
        (profile) => profile.profileType === "GLOBAL"
      )
    : undefined;

  const usProfile = hasUsAccountsPageView
    ? reportingProfiles?.reportingProfiles.find(
        (profile) => profile.profileType === "US"
      )
    : undefined;

  const globalReportingProfile: ReportingProfileRowData | undefined =
    globalProfile
      ? {
          ...globalProfile,
          payerName: globalProfile.filerId
            ? payers?.find((payer) => payer.payerId === globalProfile.filerId)
                ?.payerName
            : undefined,
          mostRecentFairMarketValue: getMostRecentFairMarketValue(
            globalProfile.yearEndFairMarketValue
          ),
        }
      : undefined;

  const usReportingProfile: ReportingProfileRowData | undefined = usProfile
    ? {
        ...usProfile,
        payerName: usProfile.filerId
          ? payers?.find((payer) => payer.payerId === usProfile.filerId)
              ?.payerName
          : undefined,
        mostRecentFairMarketValue: getMostRecentFairMarketValue(
          usProfile.yearEndFairMarketValue
        ),
      }
    : undefined;

  return {
    globalReportingProfile,
    usReportingProfile,
    isLoading: isReportingProfilesLoading,
    isError: isReportingProfilesError,
    hasGlobalAccountsPageView,
    hasUsAccountsPageView,
    shouldUnmaskValue,
    isReportingProfilesNotFound,
    reportingProfilesLoaded: isDefined(reportingProfiles),
    payers,
  };
};
