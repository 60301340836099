import { ContentSpinner } from "@taxbit-private/cosmic";

import AccountLookup from "./account-lookup/AccountLookup";
import CalloutButtons from "./callout-buttons/CalloutButtons";
import { useOverviewDataContext } from "./context/useOverviewData";
import EligibleAccountsByFormTypeChart from "./eligible-accounts-by-form-type-chart/EligibleAccountsByFormTypeChart";
import FormDataByFormTypeChart from "./form-data-by-form-type-chart/FormDataByFormTypeChart";
import StatCards from "./stat-cards/StatCards";
import TransactionsChart from "./transactions-chart/TransactionsChart";

const Overview: React.FC = () => {
  const { areInitialQueriesLoading } = useOverviewDataContext();

  if (areInitialQueriesLoading) {
    return <ContentSpinner />;
  } else {
    return (
      <>
        <AccountLookup />
        <CalloutButtons />
        <StatCards />
        <FormDataByFormTypeChart />
        <TransactionsChart />
        <EligibleAccountsByFormTypeChart />
      </>
    );
  }
};

export default Overview;
