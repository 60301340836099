import { useMutation } from "@tanstack/react-query";
import {
  createQueryMetaObject,
  useTaxBitRest,
} from "@taxbit-dashboard/commons";

import { AccountsTableParams } from "../accounts/accountsApiTypes";
import getAccountsApiParams from "../accounts/getAccountsApiParams";

export const useInitiateAccountsCsvExport = () => {
  const restSdk = useTaxBitRest();

  return useMutation(
    (params?: AccountsTableParams) => {
      if (params) {
        const {
          "page[offset]": offset,
          "page[limit]": limit,
          ...restParams
        } = getAccountsApiParams(params);
        return restSdk.accountsExport.post({
          ...restParams,
        });
      } else {
        return restSdk.accountsExport.post({});
      }
    },
    {
      ...createQueryMetaObject(restSdk.accountsExport.buildPath()),
    }
  );
};
