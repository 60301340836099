import { useQuery } from "@tanstack/react-query";
import {
  DashboardQueryKey,
  createQueryMetaObject,
  unwrapPublicApiWrappedQuery,
  useDashboardFeatureFlags,
  useTaxBitRest,
} from "@taxbit-dashboard/commons";
import {
  GetFormItemsOverviewUrlParams,
  GetTransactionsOverviewUrlParams,
  GetEligibilityOverviewUrlParams,
} from "@taxbit-dashboard/rest";

import useFilterResponseDataByFeatureFlag from "./useFilterOverviewResponseDataByFeatureFlag";

export const useGetTransactionsOverview = (
  params: GetTransactionsOverviewUrlParams
) => {
  const restSdk = useTaxBitRest();

  const query = useQuery(
    [DashboardQueryKey.OverviewTransactions, params],
    () => restSdk.overview.transactions.get(params),
    {
      ...createQueryMetaObject(restSdk.overview.transactions.buildPath()),
      keepPreviousData: true,
    }
  );

  return unwrapPublicApiWrappedQuery(query);
};

export const useGetFormItemsOverview = (
  params: GetFormItemsOverviewUrlParams
) => {
  const restSdk = useTaxBitRest();
  const { hasAccountFormItemsAccess } = useDashboardFeatureFlags();

  const query = useQuery(
    [DashboardQueryKey.OverviewFormItems, params],
    () => restSdk.overview.formItems.get(params),
    {
      ...createQueryMetaObject(restSdk.overview.formItems.buildPath()),
      keepPreviousData: true,
      enabled: hasAccountFormItemsAccess,
    }
  );

  return unwrapPublicApiWrappedQuery(query);
};

export const useGetFormItemsYears = () => {
  const restSdk = useTaxBitRest();
  const { hasAccountFormItemsAccess } = useDashboardFeatureFlags();

  const query = useQuery(
    [DashboardQueryKey.FormItemsAvailableYears],
    () => restSdk.overview.formItemsYears.get(),
    {
      ...createQueryMetaObject(restSdk.overview.formItemsYears.buildPath()),
      enabled: hasAccountFormItemsAccess,
    }
  );

  return unwrapPublicApiWrappedQuery(query);
};

export const useGetEligibilityOverview = (
  params: GetEligibilityOverviewUrlParams
) => {
  const restSdk = useTaxBitRest();
  const { hasEligibilityPageAccess } = useDashboardFeatureFlags();
  const filterResponseDataByFeatureFlag = useFilterResponseDataByFeatureFlag();

  return useQuery(
    [DashboardQueryKey.OverviewEligibility, params],
    () => restSdk.overview.eligibility.get(params),
    {
      ...createQueryMetaObject(restSdk.overview.eligibility.buildPath()),
      keepPreviousData: true,
      enabled: hasEligibilityPageAccess,
      // Filter out form types that are not enabled for this user and recalculate the total.
      select: ({ data }) =>
        filterResponseDataByFeatureFlag(data, params["filters[document_date]"]),
    }
  );
};
